export const HIDE_PATIENT_NAVIGATION_PATHS = [
    'view-case-history',
    'update-case-history',
    'view-medical-case-history',
    'update-medical-case-history',
    'change-appointment-status',
    'create-other-document',
    'update-other-document',
    'view-other-document',
    'create-scanned-document',
    'create-psychological-assessment',
    'update-psychological-assessment',
    'view-psychological-assessment',
    'create-psychological-therapy',
    'update-psychological-therapy',
    'view-psychological-therapy',
    'create-discharge-summary',
    'update-discharge-summary',
    'view-discharge-summary',
];
