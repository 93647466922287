import { Component, OnInit } from '@angular/core';

import { GlobalHelper } from 'src/app/helper/global.helper';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

    lastLoginDateTime;
    showProdModal = false;

    constructor(public globalHelper: GlobalHelper) {}

    ngOnInit() {
        this.lastLoginDateTime = this.globalHelper.getCurData('lastLogin');
    }

}
