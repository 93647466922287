export const APP_VARIABLES = {
    // LocalStorage item names
    currentUser: 'CURRENT_USER',
    lastSeenPage: 'LASTSEENPAGE',
    lastSeenTenantId: 'LAST_SEEN_TENANT_ID',
    appVersion: 'APPVERSION',
    appMaintanence: 'APPLICATION_MAINTANENCE',
    versionUpdateSuccessMsg: 'VERSIONUPDATESUCCESSMSG',
    patientDetails: 'PATIENTDETAILS',
    encounterType: 'ENCOUNTER_TYPE',
    showAvailableMedicine: 'SHOWAVAILABLEMEDICINE',
    docDuplicateErrTostar: 'DOC_DUPLICATE_ERR_TOASTR',
    userAccessURLs: 'USERACCESSURLS',
    lastExpandedCurrentOPIndex: 'LAST_EXPANDED_CURRENT_OP_INDEX',
    // Common date & time formats
    momentDateFormat : 'D-M-Y',
    momentDateTimeFormat : 'D-M-Y h:mm A',
    angularDateFormat: 'd-M-y',
    angularDateTimeFormat: 'd-M-y h:mm a',
    angularTimeFormat: 'h:mm a',
    angularMonthYearFormat: 'MMM y',
    // Hospital Organization id
    ahanaOrgId: 1,
    ajHospitalOrgId: 7,
    medclinicOrgId: 3,
    showBranchNameInTables: 'SHOW_BRANCH_NAME_IN_TABLE',
    mayoclinicOrgId: 6,
    msctrfOrgId: 4
};
