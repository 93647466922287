import { Component, Input, OnInit, AfterViewInit } from '@angular/core';

import { GlobalHelper } from '../../helper/global.helper';

@Component({
  selector: 'app-sale-return-items-form-elements',
  templateUrl: './sale-return-items-form-elements.component.html',
  styleUrls: ['./sale-return-items-form-elements.component.css']
})
export class SaleReturnItemsFormElementsComponent implements AfterViewInit, OnInit {
    @Input('modelData') modelData;
    @Input('pageTitle') pageTitle;
    @Input('formRef') formRef;
    @Input('withChecked') withChecked;
    @Input('indentId') indentId;
    @Input('fromPharmacyIndent') fromPharmacyIndent = false; // its only for the sale return item total amount section show and hide concept
    saleReturnData: any = {};
    allSaleReturnData: any = {};
    tempFinalAmt: any = 0;
    customFormCond = false;
    zeroQtyStatus = false;
    regex = /^[0-9]+$/;

    constructor(private globalHelper: GlobalHelper) {}

    ngOnInit () {
        this.saleReturnData = this.modelData.current_sale_return_data;
        this.allSaleReturnData = this.modelData.all_sale_return_data;
        if (this.allSaleReturnData && this.allSaleReturnData.return_items && (this.indentId || this.modelData.indent_id)) {
            this.allSaleReturnData.return_items.forEach((returnItem: any) => {
                this.itemChanges(returnItem);
            });
        }
    }

    ngAfterViewInit () {
        $('input').on('focus', function (ev) {
            $(this).select();
        });
        setTimeout(() => {
            $('input[type=number]').on('mousewheel', function(e) {
                $(this).blur();
            });
        }, 500);
    }

    checkReturnQuantity (item) {
        // If the current page title is update and indent id is empty string then only we need to check no stock details
        if (this.pageTitle === 'Update' && this.indentId === '') {
            const pQty = parseFloat(item.quantity),
                pOldQty = parseFloat(item.old_quantity);

            this.customFormCond = item.noStock = item.qtyExceed = false;

            if (pOldQty < pQty) {
                this.checkQuantity(item);
            } else {
                const curQty = (pOldQty - pQty); // *parseFloat(item.package_unit);
                if (curQty > parseFloat(item.batch.available_qty)) {
                    this.customFormCond = item.noStock = true;
                }
            }
        } else {
            this.checkQuantity(item);
        }
    }

    checkQuantity (item) {
        const total = parseFloat(item.quantity) + parseFloat(item.total_returned_quantity);
        this.customFormCond = item.qtyExceed = total > item.sale_quantity;
    }

    itemChanges (item, itemIndex?) {
        this.checkReturnQuantity(item);
        if (item.quantity || `${item.quantity}` === '0') {
            item['taxable_value'] = (((parseFloat(item.mrp) / (100 + parseFloat(item.cgst_percent) + parseFloat(item.sgst_percent)) * 100)) * parseFloat(item.quantity)).toFixed(2);
            if (item.discount_percentage) {
                item.discount_amount = (((item.discount_percentage / 100) * parseFloat(item.taxable_value))).toFixed(2);
            }
            if (item.discount_amount) {
                item.taxable_value = (parseFloat(item.taxable_value) - parseFloat(item.discount_amount)).toFixed(2);
            }
            item.cgst_amount = parseFloat(((parseFloat(item.taxable_value) * parseFloat(item.cgst_percent)) / 100).toFixed(2));
            item.sgst_amount = parseFloat(((parseFloat(item.taxable_value) * parseFloat(item.sgst_percent)) / 100).toFixed(2));
            item.total_amount = (parseFloat(item.taxable_value) + parseFloat(item.sgst_amount) + parseFloat(item.cgst_amount)).toFixed(2);

            // The new quantity was not updating in the save sale API payload variable.
            // It's happening pharmacy indent only. So, We'll update it for pharmacy indent only(Not for normal sale form)
            if (this.indentId !== '') {
                const srdIndex = this.modelData.sale_return_data.findIndex(srdData => srdData.sale_id === item.sale_id);

                if (srdIndex !== -1) {
                    const srdItemIndex = this.modelData.sale_return_data[srdIndex].product_items
                        .findIndex(srdItemData => srdItemData.product_id === item.product_id && srdItemData.batch_id === item.batch_id);

                    if (srdItemIndex !== -1) {
                        // Don't change it to object concat concept. Because. That's reset this.model variable values.
                        this.modelData.sale_return_data[srdIndex].product_items[srdItemIndex].quantity = item.quantity;
                        // We don't need to update discount percentage,
                        // Because, In the sale form we can't able to change sale return item discount percentage
                        this.modelData.sale_return_data[srdIndex].product_items[srdItemIndex].discount_amount = item.discount_amount;
                        this.modelData.sale_return_data[srdIndex].product_items[srdItemIndex].cgst_amount = item.cgst_amount;
                        this.modelData.sale_return_data[srdIndex].product_items[srdItemIndex].sgst_amount = item.sgst_amount;
                        this.modelData.sale_return_data[srdIndex].product_items[srdItemIndex].total_amount = item.total_amount;
                        this.modelData.sale_return_data[srdIndex].product_items[srdItemIndex].taxable_value = item.taxable_value;
                        this.modelData.sale_return_data[srdIndex].product_items[srdItemIndex].zeroQtyStatus = item.quantity &&
                            (item.quantity !== 0 || item.quantity !== 'null') && item.quantity > 0 && !item.qtyExceed && !item.noStock &&
                            this.regex.test(item.quantity);
                    }
                }
            }
            this.getTotalReturnAmt();
        }
        this.zeroQtyStatus = !(this.allSaleReturnData.return_items.every((returnItem: any) => returnItem.quantity && (`${returnItem.quantity}` !== '0' || `${returnItem.quantity}` !== 'null') && returnItem.quantity > 0 && !returnItem.qtyExceed && !returnItem.noStock && this.regex.test(returnItem.quantity)));
    }

    getTotalReturnAmt () {
        this.allSaleReturnData.total_item_vat_amount = 0;
        this.allSaleReturnData.total_item_sale_amount = 0;
        const returnItems = this.allSaleReturnData.return_items;
        for (let i = 0; i < returnItems.length; i++) {
            this.allSaleReturnData.total_item_vat_amount = (parseFloat(this.allSaleReturnData.total_item_vat_amount) + parseFloat(returnItems[i].cgst_amount) + parseFloat(returnItems[i].sgst_amount)).toFixed(2);
            this.allSaleReturnData.total_item_sale_amount = (parseFloat(this.allSaleReturnData.total_item_sale_amount) + parseFloat(returnItems[i].total_amount)).toFixed(2);
            this.tempFinalAmt = this.tempFinalAmt + parseFloat(returnItems[i].total_amount);
            if (i === returnItems.length - 1) {
                if (this.allSaleReturnData.total_item_discount_percent) {
                    this.finalDiscount();
                } else {
                    this.allSaleReturnData.total_item_amount = this.allSaleReturnData.total_item_sale_amount;
                    this.allSaleReturnData.bill_amount = Math.round(parseFloat(this.allSaleReturnData.total_item_amount)).toFixed(2);
                    this.allSaleReturnData.roundoff_amount = (parseFloat(this.allSaleReturnData.bill_amount) - parseFloat(this.allSaleReturnData.total_item_amount)).toFixed(2);
                }
            }
        }
        if (this.modelData.sale_return_data.length) {
            for (let i = 0; i < this.modelData.sale_return_data.length; i++) {
                const cGSTAmount = this.modelData.sale_return_data[i].product_items
                    .reduce((sum: any, curr: any) => sum + parseFloat(curr.cgst_amount), 0),
                    sGSTAmount = this.modelData.sale_return_data[i].product_items
                        .reduce((sum: any, curr: any) => sum + parseFloat(curr.sgst_amount), 0),
                    sale_amount = this.modelData.sale_return_data[i].product_items
                        .reduce((sum: any, curr: any) => sum + parseFloat(curr.total_amount), 0);

                this.modelData.sale_return_data[i].total_item_sale_amount = parseFloat(sale_amount).toFixed(2);
                this.modelData.sale_return_data[i].total_item_vat_amount = (parseFloat(cGSTAmount) + parseFloat(sGSTAmount)).toFixed(2);
                if (this.modelData.sale_return_data[i].total_item_discount_percent) {
                    this.modelData.sale_return_data[i].total_item_discount_amount = ((parseFloat(
                        this.modelData.sale_return_data[i].total_item_sale_amount
                    ) * this.modelData.sale_return_data[i].total_item_discount_percent) / 100).toFixed(2);
                    this.modelData.sale_return_data[i].total_item_amount = (parseFloat(
                        this.modelData.sale_return_data[i].total_item_sale_amount
                    ) - parseFloat(this.modelData.sale_return_data[i].total_item_discount_amount)).toFixed(2);
                    this.modelData.sale_return_data[i].bill_amount = Math.round(parseFloat(
                        this.modelData.sale_return_data[i].total_item_amount
                    )).toFixed(2);
                    this.modelData.sale_return_data[i].roundoff_amount = (parseFloat(
                        this.modelData.sale_return_data[i].bill_amount
                    ) - parseFloat(this.modelData.sale_return_data[i].total_item_amount)).toFixed(2);
                } else {
                    this.modelData.sale_return_data[i].total_item_amount = this.modelData.sale_return_data[i].total_item_sale_amount;
                    this.modelData.sale_return_data[i].bill_amount = Math.round(parseFloat(
                        this.modelData.sale_return_data[i].total_item_amount
                    )).toFixed(2);
                    this.modelData.sale_return_data[i].roundoff_amount = (parseFloat(
                        this.modelData.sale_return_data[i].bill_amount
                    ) - parseFloat(this.modelData.sale_return_data[i].total_item_amount)).toFixed(2);
                }
            }
        }
    }

    removeProduct (returnIndex) {
        const returnItems = this.allSaleReturnData.return_items,
            returnBillNo = returnItems[returnIndex].bill_no,
            returnProductId = returnItems[returnIndex].product_id,
            returnSaleId = returnItems[returnIndex].sale_id,
            returnProductBatchId = returnItems[returnIndex].batch_id;

        // Removing delete icon clicked row return item
        returnItems.splice(returnIndex, 1);

        if (returnItems.length > 0) {
            const productItems = [];

            // If the current form has indent return data means,
            // We should remove same item in the below variable(this.modelData.sale_return_data) too.
            // Because, We're sending this the below variable in the save sale API payload.
            if (this.modelData.indentReturn && this.modelData.indentReturn.length) {
                const srDataIndex = this.modelData.sale_return_data
                    .findIndex(({ bill_no, sale_id }) => bill_no === returnBillNo || sale_id === returnSaleId);

                if (srDataIndex !== -1) {
                    const srProdIndex = this.modelData.sale_return_data[srDataIndex].product_items.findIndex(srdPIData => (
                        srdPIData.product_id === returnProductId && srdPIData.batch_id === returnProductBatchId
                    ));
                    if (srProdIndex !== -1) { this.modelData.sale_return_data[srDataIndex].product_items.splice(srProdIndex, 1); }
                    // We shouldn't send sale data without sale item data in the save sale API payload.
                    if (this.modelData.sale_return_data[srDataIndex].product_items.length === 0) {
                        this.modelData.sale_return_data.splice(srDataIndex, 1);
                    }
                }
            }

            // We are doing the total calculation part from the below code, Total was calculated by model.sale_return_data so this section need to place here only
            returnItems.forEach((item: any) => {
                if (item.bill_no === returnBillNo) {
                    productItems.push(item);
                }
            });

            if (!this.modelData.indentReturn || !this.modelData.indentReturn.length) {
                const selectedReturnDataIndex = this.modelData.sale_return_data.findIndex(({ bill_no }) => bill_no === returnBillNo);
                if (productItems.length > 0) {
                    if (selectedReturnDataIndex > -1) {
                        this.modelData.sale_return_data[selectedReturnDataIndex].product_items = productItems;
                    }
                } else {
                    this.modelData.sale_return_data.splice(selectedReturnDataIndex, 1);
                }
            }

            // In Item charges we are doing the total calculation for sale return so it should be placed in bottom
            returnItems.forEach(item => {
                this.itemChanges(item);
            });

        } else {
            this.modelData.all_sale_return_data = {
                total_item_vat_amount: '0.00',
                total_item_sale_amount: '0.00',
                total_item_discount_percent: 0,
                total_item_discount_amount: '0.00',
                total_item_amount: '0.00',
                roundoff_amount: '0.00',
                bill_amount: '0.00',
                return_items: returnItems
            };
            this.modelData.sale_return_data = [];
        }
    }

    finalDiscount () {
        this.allSaleReturnData.total_item_discount_amount = ((parseFloat(this.saleReturnData.total_item_sale_amount) * this.saleReturnData.total_item_discount_percent) / 100).toFixed(2);
        this.allSaleReturnData.total_item_amount = (parseFloat(this.saleReturnData.total_item_sale_amount) - parseFloat(this.saleReturnData.total_item_discount_amount)).toFixed(2);
        this.allSaleReturnData.bill_amount = Math.round(parseFloat(this.saleReturnData.total_item_amount)).toFixed(2);
        this.allSaleReturnData.roundoff_amount = (parseFloat(this.saleReturnData.bill_amount) - parseFloat(this.saleReturnData.total_item_amount)).toFixed(2);
    }
}
