import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { ErrorHandler, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { PerfectScrollbarModule,
    PERFECT_SCROLLBAR_CONFIG,
    PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { ToastrModule } from 'ngx-toastr';
import { HotkeyModule, IHotkeyOptions } from 'angular2-hotkeys';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { environment } from '../environments/environment';

import { AuthGuard } from './services/guards/auth.guard';
import { LoggedInGuard } from './services/guards/logged-in.guard';
import { AccessGuard } from './services/guards/access.guard';
import { EnvServiceProvider } from './services/env/env.service.provider';
import { PusherService } from './services/pusher/pusher.service';
import { PatientService } from './modules/patient/services/patient.service';
import { ErrorHandlerService } from './services/error/error-handler.service';

import { JwtInterceptor } from './helper/jwt.interceptor';
import { HttpRequestTrackingInterceptor } from './helper/httpRequestTracking.interceptor';
import { ErrorInterceptor } from './helper/error.interceptor';
import { GlobalHelper } from './helper/global.helper';

import { Approutes } from './app-routing.module';
import { ComponentsModule } from './components/components.module';

import { AppComponent } from './app.component';
import { BlankComponent } from './layouts/blank/blank.component';
import { FullWidthLeftComponent } from './layouts/full-width-left/full-width-left.component';
import { FullWidthComponent } from './layouts/full-width/full-width.component';
import { PatientsComponent } from './layouts/patients/patients.component';

import { PatientReducer } from './modules/patient/store/Patient/patients.reducer';
import { StoreEffects } from './modules/patient/store/Patient/patients.effects';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    wheelSpeed: 1,
    wheelPropagation: true,
    minScrollbarLength: 20
};

const options: IHotkeyOptions = {
    cheatSheetCloseEsc: true
};

@NgModule({
    declarations: [
        AppComponent,
        BlankComponent,
        FullWidthLeftComponent,
        FullWidthComponent,
        PatientsComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(Approutes),
        PerfectScrollbarModule,
        ComponentsModule,
        NgbModule,
        ToastrModule.forRoot({closeButton: false, tapToDismiss: true}),
        HotkeyModule.forRoot(options),
        NgIdleKeepaliveModule.forRoot(),
        LoggerModule.forRoot({ level: NgxLoggerLevel.DEBUG, serverLogLevel: NgxLoggerLevel.DEBUG, serverLoggingUrl: '' }),
        StoreModule.forRoot({'data': PatientReducer}),
        EffectsModule.forRoot([StoreEffects]),
        StoreDevtoolsModule.instrument({
            maxAge: 25, // Retains last 25 states
            logOnly: environment.production, // Restrict extension to log-only mode
        }),
    ],
    providers: [
        AuthGuard,
        LoggedInGuard,
        AccessGuard,
        GlobalHelper,
        PusherService,
        PatientService,
        EnvServiceProvider, {
        provide: PERFECT_SCROLLBAR_CONFIG,
        useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }, { provide: ErrorHandler,
        useClass: ErrorHandlerService
    }, {
        provide: HTTP_INTERCEPTORS,
        useClass: JwtInterceptor,
        multi: true
    }, {
        provide: HTTP_INTERCEPTORS,
        useClass: HttpRequestTrackingInterceptor,
        multi: true
    }, {
        provide: HTTP_INTERCEPTORS,
        useClass: ErrorInterceptor,
        multi: true
    }],
    bootstrap: [AppComponent]
})
export class AppModule {}
