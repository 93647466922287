import { Component, OnInit, ViewChild, ViewContainerRef} from '@angular/core';

import { NgbModal, NgbModalRef, NgbModalOptions, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { GlobalHelper } from '../../../helper/global.helper';
import { SupportService } from '../../../services/support/support.service';

@Component({
    selector: 'app-feedback-form-modal',
    templateUrl: './feedback-form-modal.component.html',
    styleUrls: ['./feedback-form-modal.component.css']
})
export class FeedbackFormModalComponent implements OnInit {
    @ViewChild('feedbackForm') feedbackForm: NgbModalRef;
    modelRef;
    submitSub;
    submitTimerId;
    loadingForm = false;
    model: any = {};

    constructor(private modalService: NgbModal, private viewContainerRef: ViewContainerRef, private globalHelper: GlobalHelper,
        private modelService: SupportService) { }

    ngOnInit() {
        const self = this;
        this.model.type = 'bug';
        this.model.attachments = [];
        this.model.referer_url = window.location.href;
        setTimeout(() => {
            const option: NgbModalOptions = {
                centered: true,
                size: 'lg'
            };
            self.modelRef = self.modalService.open(self.feedbackForm, option);
            self.modelRef.result.then((result) => {}, (reason) => {
                if (reason === ModalDismissReasons.ESC || reason === ModalDismissReasons.BACKDROP_CLICK) {
                    self.getParentComponent().showProdModal = false;
                }
            });
        });
    }

    onFileChange(event: any) {
        const input = event.target as HTMLInputElement;
        if (input && input.files) {
            for (const file of Array.from(input.files)) {
                const reader = new FileReader();
                reader.onload = (e: any) => {
                    const imageUrl = e.target.result;
                    const attachment = { file: file, image: imageUrl };
                    this.model.attachments.push(attachment);
                };
                reader.readAsDataURL(file);
            }
        }
    }

    onTypeChange(event: any, form) {
        const type = event.target.value;

        if (type === 'bug') {
            this.model.feature_request = '';
        } else if (type === 'feature') {
            this.model.steps_to_reproduce = this.model.actual_results = this.model.expected_results = '';
        }

        form.submitted = false;
        this.model.type = type;
    }

    removeImage(index: number) {
        this.model.attachments.splice(index, 1);
    }

    getParentComponent() {
        return this.viewContainerRef[ '_data' ].componentView.component.viewContainerRef[ '_view' ].component;
    }

    closeModal () {
        this.getParentComponent().showProdModal = false;
        this.modelRef.close();
    }

    onSubmit() {
        const self = this;

        this.loadingForm = true;

        if (this.submitSub) {
            this.submitSub.unsubscribe();
        }

        if (this.submitTimerId) {
            clearTimeout(this.submitTimerId);
        }

        this.submitTimerId = setTimeout(() => {
            const formData = new FormData();

            Object.keys(self.model).forEach(key => {
                if (key !== 'attachments') {
                    formData.append(key, self.model[key]);
                }
            });

            self.model.attachments.forEach((attachment, index) => {
                formData.append(`attachments[${index}]`, attachment.file);
            });

            self.submitSub = self.modelService.createTask(formData).subscribe(() => {
                self.globalHelper.toastrOpen('S', 'Your feedback was saved successfully!');
                self.loadingForm = self.getParentComponent().showProdModal = false;
                self.modelRef.close();
            }, error => {
                self.loadingForm = self.getParentComponent().showProdModal = false;
                self.modelRef.close();
            });
        }, 250);
    }

}
