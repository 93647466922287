import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpParams } from '@angular/common/http';

import * as moment from 'moment';
import * as momentWithTZ from 'moment-timezone';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs/Subject';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';

import {
    APP_VARIABLES,
    CHART_COLORS,
    DATATABLE_VARIABLES,
    DATE_RANGE_PICKER_OPTIONS,
    MY_MOMENT_DATE_TIME_FORMATS,
    LINE_CHART_OPTIONS,
    THEME_OPTIONS,
    PATIENT_MODULE_ROUTES,
    ACCESS_ROUTES,
    SCHEDULE_TIMINGS,
    GRIDSTER_OPTIONS,
    LAB_MODULES,
    DURATION,
    INTENT_STATUS,
    ORGANIZATIONS,
    CANCELABLE_REQUEST_METHODS,
    ABORT_REQUEST_PATHS,
    HIDE_PATIENT_NAVIGATION_PATHS,
} from '../constants';
import { EnvService } from '../services/env/env.service';

declare var require: any;
const numberToWords = require('num-words');

@Injectable()
export class GlobalHelper {

    public userThemeOptions: any = {};
    public patientDetails: any = {};
    public showSearch = false;
    storageSub = new Subject<object>();
    toasrOpenTime: any;
    curYear = this.getMomentDatas('year', new Date());

    constructor (public router: Router, private envService: EnvService,
        private toastr: ToastrService) {}

    getConstantsValue (cName) {
        switch (cName) {
            case 'app':
                return APP_VARIABLES;

            case 'chart-colors':
                return CHART_COLORS;

            case 'data-table-options':
                return DATATABLE_VARIABLES;

            case 'date-time-picker-format':
                return MY_MOMENT_DATE_TIME_FORMATS;

            case 'date-range-picker-option':
                return DATE_RANGE_PICKER_OPTIONS;

            case 'line-chart-options':
                return LINE_CHART_OPTIONS;

            case 'theme-options':
                return THEME_OPTIONS;

            case 'patient-module-routes':
                return PATIENT_MODULE_ROUTES;

            case 'access-routes':
                return ACCESS_ROUTES;

            case 'schedule-timings':
                return SCHEDULE_TIMINGS;

            case 'gridster-options':
                return GRIDSTER_OPTIONS;

            case 'lab-modules':
                return [...Array.from(new Set(LAB_MODULES))];

            case 'procedure-duration':
                return DURATION;

            case 'pharmachy_intent':
                return INTENT_STATUS;

            case 'organizations':
                return ORGANIZATIONS;

            case 'cancelable_request_methods':
                return CANCELABLE_REQUEST_METHODS;

            case 'abort_request_paths':
                return ABORT_REQUEST_PATHS;

            case 'hide-patient-navigation-paths':
                return HIDE_PATIENT_NAVIGATION_PATHS;

            default:
                break;
        }
    }

    lsPush (name, value) {
        if (typeof value === 'object') {
            localStorage.setItem(name, JSON.stringify(value));
        } else if (name === this.getConstantsValue('app').patientDetails && value) {
            sessionStorage.setItem(name, value);
            value = JSON.parse(value);
            this.patientDetails = value;
            this.storageSub.next(value);
        } else if (name === this.getConstantsValue('app').encounterType && value) {
            sessionStorage.setItem(name, value);
        } else {
            localStorage.setItem(name, value);
        }
    }

    watchStorage(): Observable<any> {
        return this.storageSub.asObservable();
    }

    removePDLS() {
        this.removeSessionStorage(this.getConstantsValue('app').patientDetails);
        this.storageSub.next({});
    }

    removeSessionStorage (key) {
        sessionStorage.removeItem(key);
    }

    sortArrayByFieldName (array, key) {
        array.sort((a: any, b: any) => {
            if (a[key] < b[key]) {
              return -1;
            } else if (a[key] > b[key]) {
              return 1;
            } else {
              return 0;
            }
        });
        return array;
    }

    getCurData (dataType) {
        switch (dataType) {
            case 'user':
                return JSON.parse(localStorage.getItem(APP_VARIABLES.currentUser)) || {};

            case 'user-access-urls':
                const userAccURLs = JSON.parse(localStorage.getItem(APP_VARIABLES.userAccessURLs));
                if (userAccURLs) {
                    return userAccURLs.userAccessDatas || {};
                }

                return {};

            case 'pat-dtl':
                return JSON.parse(sessionStorage.getItem(APP_VARIABLES.patientDetails)) || {};

            case 'enctr-dtl':
                return JSON.parse(sessionStorage.getItem(APP_VARIABLES.encounterType)) || {};

            case 'sam':
                return JSON.parse(localStorage.getItem(APP_VARIABLES.showAvailableMedicine)) || {};

            case 'ddet':
                return localStorage.getItem(APP_VARIABLES.docDuplicateErrTostar) || '';

            case 'curUserId':
                return this.getCurData('user').user_id || '';

            case 'curUserName':
                return this.getCurData('user').name || '';

            case 'curTenantName':
                return this.getCurData('user').tenant_name || '';

            case 'appSpaceID':
                return this.getCurData('user').app_space || '';

            case 'curTenantId':
                return this.getCurData('user').tenant_id || '';

            case 'curToken':
                return this.getCurData('user').token || '';

            case 'userTimeout':
                return this.getCurData('user').timeout || '';

            case 'showPresProduct':
                return this.getCurData('user').show_prescription_product || '0';

            case 'careProvider':
                return this.getCurData('user').care_provider || '';

            case 'curOrgId':
                return this.getCurData('user').org_id || '';

            case 'lastExpandCurOP':
                return JSON.parse(localStorage.getItem(APP_VARIABLES.lastExpandedCurrentOPIndex)) || [];

            case 'lastLogin':
                const userDatas = this.getCurData('user');
                if (userDatas && userDatas.last_login) {
                    return this.getMomentDatas('app-date-time', userDatas.last_login) || '';
                }
                return '';

            case 'appVersion':
                return localStorage.getItem(this.getConstantsValue('app').appVersion) || '';

            case 'vusm':
                return JSON.parse(localStorage.getItem(this.getConstantsValue('app').versionUpdateSuccessMsg)) || '';

            case 'appMaintanence':
                return JSON.parse(localStorage.getItem(this.getConstantsValue('app').appMaintanence)) || 'false';

            case 'orgDocumentLogo':
                return this.getCurData('user').document_logo || 'ah-logo.png';

            case 'payMode':
                const paymentMode = _.get(this.getCurData('user'), 'payment_type', null);
                return _.get(paymentMode, 'payment_modes', '');

            case 'payType':
                const paymentTypes = _.get(this.getCurData('user'), 'payment_type', null);
                return this.sortArrayByFieldName(_.get(paymentTypes, 'sale_payment_types', []), 'order');

            case 'showBranch':
                return localStorage.getItem(this.getConstantsValue('app').showBranchNameInTables) || 0;

            case 'intentStatus':
                return this.getConstantsValue('pharmachy_intent');

            default:
                break;
        }
    }

    getAPIBaseUrl () {
        return `${this.envService.baseUrl}${this.envService.apiVersion}/`;
    }

    updatePatDatas (changeDatas) {
        const curData = { ...this.getCurData('pat-dtl'), ...changeDatas };
        sessionStorage.setItem(APP_VARIABLES.patientDetails, JSON.stringify(curData));
        this.storageSub.next(curData);
    }

    updateCurData (changeDatas) {
        const curData = { ...this.getCurData('user'), ...changeDatas };
        localStorage.setItem(APP_VARIABLES.currentUser, JSON.stringify(curData));
    }

    getDtOptions (options) {
        return { ...this.getConstantsValue('data-table-options'), ...options};
    }

    getDateDatas (type, date?) {
        if (type === 'string' && date) {
            return date.year + '-' + date.month + '-' + date.day;
        } else if (type === 'string') {
            const changeDate = new Date();
            return (changeDate).getFullYear() + '-' + ((changeDate).getMonth() + 1) + '-' + (changeDate).getDate();
        } else if (type === 'current') {
            const changeDate = new Date();
            return {
                year: (changeDate).getFullYear(),
                month: (changeDate).getMonth() + 1,
                day: (changeDate).getDate()
            };
        } else if (type === 'object' && date) {
            const changeDate = new Date(date);
            return {
                year: (changeDate).getFullYear(),
                month: (changeDate).getMonth() + 1,
                day: (changeDate).getDate()
            };
        } else {
            return '';
        }
    }

    getMomentDatas (format?, dateData?) {
        if (format) {
            if (dateData) {
                if (format === 'app-date') { return moment(dateData).format(this.getConstantsValue('app').momentDateFormat); }
                if (format === 'app-date-time') { return moment(dateData).format(this.getConstantsValue('app').momentDateTimeFormat); }
                if (format === 'month') { return moment(dateData).month(); }
                if (format === 'year') { return moment(dateData).year(); }
                if (format === 'endOf') { return moment(dateData).endOf('day'); }
                return moment(dateData).format(format);
            } else {
                if (format === 'app-date') { return moment().format(this.getConstantsValue('app').momentDateFormat); }
                if (format === 'app-date-time') { return moment().format(this.getConstantsValue('app').momentDateTimeFormat); }
                return moment().format(format);
            }
        } else {
            if (dateData) {
                return moment(dateData);
            } else {
                return moment();
            }
        }
    }

    goDateGvnDay (noOfDay, type?, dateData?) {
        const date = dateData || new Date();
        if (type === 'add') {
            date.setDate(date.getDate() + noOfDay);
        } else {
            date.setDate(date.getDate() - noOfDay);
        }
        return moment(date);
    }

    compareDate (date1, date2?, time?) {
        if (time) {
            const beginTime = moment(date1, 'h:mma'),
            endTime = moment(date2, 'h:mma');
            return beginTime.isBefore(endTime);
        } else {
            if (date1 && date2) {
                return moment(date1).isAfter(date2);
            }
            return moment().isAfter(date1);
        }
    }

    // If the specific moment occurred 2 minutes ago means, It should return 'true'
    isMomentAgo (specificMoment, momentAgoType, momentAgoValue) {
        return moment(specificMoment).isBetween(moment().subtract(momentAgoValue, momentAgoType), moment());
    }

    isToday(date) {
        return moment(this.getMomentDatas('YYYY-MM-DD')).isSame(this.getMomentDatas('YYYY-MM-DD', date));
    }

    // Checking logged browser was chrome or not
    isChromeBrowser () {
        const userAgent = window.navigator.userAgent;

        return userAgent.indexOf('Chrome') !== -1;
    }

    isEmpty (data) {
        return _.isEmpty(data);
    }

    getMomentDiff (date1, type, date2?) {
        if (date2) {
            const date = moment(date1);
            return date.diff(date2, type);
        } else {
            return moment().diff(date1, type);
        }
    }

    owlMinDate(type, dateData?) {
        if (type === 'current') {
            const date = new Date();
            return new Date(date.getFullYear(), date.getMonth(), date.getDate());
        } else if (type === 'object') {
            return new Date(dateData.getFullYear(), dateData.getMonth(), dateData.getDate());
        } else if (type === 'string' && dateData) {
            const date = new Date(dateData);
            return new Date(date.getFullYear(), date.getMonth(), date.getDate());
        } else { return; }
    }

    getTimeOutDropDownDatas () {
        const timeOutDatas = [];
        for (let i = 10; i < 70; i += 10) {
            timeOutDatas.push(i);
        }
        return timeOutDatas;
    }

    getParamsData () {
        return this.router.url.split('/')[this.envService.patientPathCount + 1];
    }

    setColumnIndexes (colNameArr) {
        const colIndObj = {};
        colNameArr.map((cName, cIndex) => colIndObj[cName] = cIndex + 1);
        return colIndObj;
    }

    convertNumberToWord (number) {
        number = parseInt(number);
        if (number) {
            return this.toTitleCase(numberToWords(number));
        }
        return '';
    }

    getRandomColor() {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    arrayUnion (arr1, arr2?) {
        if (arr2) {
            return Array.from(new Set(arr1.concat(arr2)));
        } else {
            return Array.from(new Set(arr1)).filter(Number);
        }
    }

    addNumberOfDaysToToday (numOfDay, type?) {
        if (numOfDay) {
            const someDate = new Date();
            someDate.setDate(someDate.getDate() + parseInt(numOfDay));
            if (type === 'object') {
                return {
                    year: (someDate).getFullYear(),
                    month: (someDate).getMonth() + 1,
                    day: (someDate).getDate()
                };
            }
            return someDate;
        }
        return '';
    }

    getMaskDate (date) {
        if (date.length <= 8) {
            return `${date.substring(4, 8)}-${date.substring(4, 2)}-${date.substring(0, 2)}`;
        } else if (date.search('/') !== -1) {
            return `${date.substring(6, 10)}-${date.substring(3, 5)}-${date.substring(0, 2)}`;
        }
        return date;
    }

    getCurDateObjWithTime (type, date?) {
        const todayDateObj = date ? new Date(date.year(), date.month(), date.date()) : new Date();
        if (type === 'min') {
            todayDateObj.setHours(0);
            todayDateObj.setMinutes(0);
            todayDateObj.setSeconds(0);
        } else if (type === 'max') {
            todayDateObj.setHours(23);
            todayDateObj.setMinutes(59);
            todayDateObj.setSeconds(59);
        }
        return todayDateObj;
    }

    getDifDayCountInTwoDate (date1, date2) {
        const todayDateObj = moment(moment()).startOf('day');
        date1 = date1 === 'today' ? todayDateObj : moment(date1);
        date2 = date2 === 'today' ? todayDateObj : moment(date2);
        return Math.floor(moment.duration(date2.diff(date1)).asDays());
    }

    getDifBWTwoSecs (time1, time2) {
        const startTime = moment(time1, 'HH:mm:ss'),
        endTime = moment(time2, 'HH:mm:ss'),
        duration: any = moment.duration(endTime.diff(startTime)),
        sec = duration.asSeconds();
        if (sec >= 0) {
            return sec;
        }
        return '';
    }

    getNoOfDayinMon (date?) {
        return moment(date ? date : new Date(), 'YYYY-MM').daysInMonth();
    }

    getStartofGvnDate (period?, date?) {
        if (period === 'month') {
            if (date) {
                return moment(date).clone().startOf('month');
            }
            return moment().startOf('month');
        } else if (period === 'week') {
            if (date) {
                return moment(date).startOf('week');
            }
            return moment().startOf('week');
        } else {
            if (date) {
                return moment(date).startOf('day');
            }
            return moment().startOf('day');
        }
    }

    getWeekStartEndDates (date?) {
        const getDate = date ? date : new Date();
        return {from_date: moment(getDate).startOf('week'), to_date: moment(getDate).endOf('week')};
    }

    getServerDateTime (format?) {
        return momentWithTZ().tz('Asia/Kolkata').format(format);
    }

    getLast4Years () {
        const years: any = ['yearly'];
        for (let i = 4; i >= 0; i--) {
            years.push(moment().subtract(i, 'year').format('YYYY'));
        }
        return years;
    }

    toTitleCase (str) {
        if (str) {
            return str.replace(/\w\S*/g, function(txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            });
        }
    }

    hyphenTxtToTitleCase (text) {
        if (text) {
            const result = text.replace( /-([a-z])/ig, function( all, letter ) {
                return letter.toUpperCase();
            });
            return this.camelCaseToTitleCase(result);
        }
        return '';
    }

    camelCaseToTitleCase (text) {
        const result = text.replace( /([A-Z])/g, ' $1' );
        return result.charAt(0).toUpperCase() + result.slice(1);
    }

    getPatModCurPath (type?) { // Get Patient Modules Current Routes
        const curPathArr = location.pathname.split('/'),
        firstPath = curPathArr[this.envService.patientPathCount - 1],
        secondPath = curPathArr[this.envService.patientPathCount];
        if (type === 'header') {
            if (secondPath) {
                return `${firstPath}.${secondPath}`;
            } else {
                return firstPath;
            }
        } else if (type === 'patient') {
            return secondPath;
        } else if (this.getConstantsValue('access-routes').indexOf(firstPath) === -1) {
            if (secondPath) {
                return `${firstPath}/${secondPath}`;
            } else {
                return firstPath;
            }
        } else {
            return '';
        }
    }

    changePatient (patientGUID) {
        const curPath = this.getPatModCurPath('patient'),
        patModlRoutes = this.getConstantsValue('patient-module-routes');
        for (const routeName in patModlRoutes) {
            if (patModlRoutes[routeName].indexOf(curPath) !== -1) {
                this.router.navigate([`patient/${patModlRoutes[routeName][0]}/${patientGUID}`]);
                return false;
            }
        }
        this.router.navigate([`patient/${curPath}/${patientGUID}`]);
    }

    getDocFieldByPath (curPath, fieldName) {
        const allDocTypes = this.getDocumentTypes(),
        curDocType: any = allDocTypes.filter(docType => docType.path === curPath);
        if (curDocType && curDocType.length) { return curDocType[0][fieldName]; }
        return '';
    }

    getDocFieldByCode (curVal, fieldName) {
        const allDocTypes = this.getDocumentTypes(),
        curDocType: any = allDocTypes.filter(docType => docType.value === curVal);
        if (curDocType && curDocType.length) { return curDocType[0][fieldName]; }
        return '';
    }

    checkUserAccess (checkUrl?) {
        const userAccessUrls = this.getCurData('user-access-urls'),
        rsrcUrl = checkUrl || this.getPatModCurPath();
        return this.getConstantsValue('access-routes').indexOf(rsrcUrl) !== -1 || (Array.isArray(userAccessUrls) && userAccessUrls.some(userAccDatas => userAccDatas.resource_url === rsrcUrl));
    }

    checkUserAccessWithRescrName (checkRN) {
        const userAccessUrls = this.getCurData('user-access-urls');
        return (Array.isArray(userAccessUrls) && userAccessUrls.length && userAccessUrls.some(uAccData => uAccData.resource_name === checkRN ));
    }

    calFrequency (value, index) {
        value = value.split('-')[index];
        if (`${value}` === '0') {
            return '-';
        } else {
          const freqVal = value.split('.')[0], freqDecimalVal = value.split('.')[1];
          const returnDatas = [freqVal];
          if (freqVal && `${freqVal}` !== '0') {
            if (`${freqDecimalVal}` === '25') {
                returnDatas.push({text: ' 1/4', italics: true, fontSize: 8, color: '#333333'});
            } else if (`${freqDecimalVal}` === '75') {
                returnDatas.push({text: ' 3/4', italics: true, fontSize: 8, color: '#333333'});
            } else if (`${freqDecimalVal}` === '5') {
                returnDatas.push({text: ' 1/2', italics: true, fontSize: 8, color: '#333333'});
            }
            return returnDatas || '';
          } else {
            if (`${freqDecimalVal}` === '25') {
                return '1/4';
            } else if (`${freqDecimalVal}` === '75') {
                return '3/4';
            } else if (`${freqDecimalVal}` === '5') {
                return '1/2';
            }
          }
        }
        return value || '';
    }

    convUC (data) {
        if (typeof data === 'string') {
            return data.toUpperCase();
        } else {
            return '-';
        }
    }

    guidGenerator () {
        return Guid.create()['value'];
    }

    checkAccessToGo () {
        if (this.checkUserAccess('in-patients')) {
            this.router.navigate(['in-patients']);
        } else if (this.checkUserAccess('out-patients')) {
            this.router.navigate(['out-patients']);
        } else if (this.checkUserAccess('pharmacy/dashboard')) {
            this.router.navigate(['pharmacy']);
        } else if (this.checkUserAccess('configuration/organization')) {
            this.router.navigate(['configuration']);
        } else {
            // this.router.navigate(['configuration']);
            this.router.navigate(['my-works']);
        }
    }

    getStaticArrDatas (arrName) {
        switch (arrName) {
            case 'ageRange':
                return this.getAgeRangeFormat();
            case 'gender':
                return this.getAllGenders();
            case 'paymentStatus':
                return this.getAllPaymentStatus();
            case 'lab-modules':
                return this.getConstantsValue(arrName);
            case 'procedure-duration':
                return this.getConstantsValue(arrName);
            default:
                break;
        }
    }

    getParticularArrVal (arrName, type, value) {
        const formatDatas = this.getStaticArrDatas(arrName);
        const findObj = formatDatas.find(fDatas => fDatas[type] === value);
        if (findObj) {
            return findObj[type === 'label' ? 'value' : 'label'];
        }
        return '';
    }

    logout () {
        localStorage.setItem(APP_VARIABLES.lastSeenTenantId, this.getCurData('curTenantId'));
        localStorage.removeItem(APP_VARIABLES.currentUser);
        sessionStorage.removeItem(APP_VARIABLES.patientDetails);
        localStorage.removeItem(APP_VARIABLES.showAvailableMedicine);
        localStorage.removeItem(APP_VARIABLES.userAccessURLs);
        localStorage.removeItem(APP_VARIABLES.showBranchNameInTables);
        localStorage.setItem(APP_VARIABLES.lastSeenPage, location.pathname);
        const bodyClass = document.getElementsByTagName('body')[0]['className'],
        themeClassIndex = bodyClass.search('main-theme-');
        if (themeClassIndex !== -1) {
            document.getElementsByTagName('body')[0]['className'] = bodyClass.replace(bodyClass.substring(themeClassIndex, themeClassIndex + 12), '');
        }
        this.userThemeOptions = {};
        this.router.navigate(['login']);
    }

    // Return Static Value Methods

    getDocumentTypes () {
        return [{
            label: 'Case History',
            value: 'CH',
            path: 'create-case-history'
        }, {
            label: 'Scanned Documents',
            value: 'SD',
            path: 'create-scanned-document'
        }, {
            label: 'Other Documents',
            value: 'OD',
            path: 'create-other-document'
        }, {
            label: 'Medical Case History',
            value: 'MCH',
            path: 'create-medical-case-history'
        }, {
            label: 'Discharge Summary',
            value: 'DS',
            path: 'create-discharge-summary'
        }, {
            label: 'Psychological Assessment',
            value: 'PA',
            path: 'create-psychological-assessment'
        }, {
            label: 'Psychological Therapy',
            value: 'PT',
            path: 'create-psychological-therapy'
        }];
    }

    getCareTakerName (careTakerID) {
        switch (careTakerID) {
            case 1:
                return 'Father';

            case 2:
                return 'Mother';

            case 3:
                return 'Husband';

            case 4:
                return 'Wife';

            case 5:
                return 'Son';

            case 6:
                return 'Daughter';

            case 7:
                return 'Friend';

            case 8:
                return 'Other';

            default:
                break;
        }
    }

    getMaritalStatusName (maritalStatusID) {
        switch (maritalStatusID) {
            case 'C':
                return 'Children';

            case 'U':
                return 'Un married';

            case 'M':
                return 'Married';

            case 'S':
                return 'Separated';

            case 'D':
                return 'Divorced';

            case 'W':
                return 'Widow';

            default:
                break;
        }
    }

    getGenderName (genderPrefix) {
        switch (genderPrefix) {
            case 'O':
                return 'Others';

            case 'M':
                return 'Male';

            case 'F':
                return 'Female';

            default:
                return '';
        }
    }

    getObjectValue (obj, fieldName, defaultValue: any = '') {
        return _.get(obj, fieldName, defaultValue);
    }

    getObjectWithFieldOmit (obj, fieldName) {
        return _.omit(obj, fieldName);
    }

    getPaymentTxt (type) {
        const paymentMode = this.getCurData('payMode'),
            paymentTypes = this.getCurData('payType');
        paymentTypes.forEach(({ key, name }) => {
            if (!Object.keys(paymentMode).includes(key)) { paymentMode[key] = { name }; }
        });
        const payType = _.get(paymentMode, `${type}.name`, null);
        switch (type) {
            case 'CA':
                return payType || 'Cash';
            case 'CR':
                return payType || 'Credit';
            case 'CD':
                return payType || 'Card';
            case 'CH':
                return payType ||  'Cheque';
            case 'COD':
                // return 'Cash On Delivery';
                return payType || 'Courier';
            case 'ON':
                return payType || 'Online';
            default:
                break;
        }
    }

    getAllPaymentStatus () {
        return [{
            label: 'Partial Completed',
            value: 'PC'
        }, {
            label: 'Pending',
            value: 'P'
        }, {
            label: 'Completed',
            value: 'C'
        }];
    }

    getAllCardTypes () {
        return ['Visa', 'MasterCard', 'Maestro', 'Visa Debit', 'MasterCard Debit', 'Rupay', 'QR Code'];
    }

    getOPTabDatas () {
        return [{
            key: 'current',
            label: 'Today\'s Appointments',
            resrcUrl: 'out-patients/current',
            icon: 'fa fa fa-user-md'
        }, {
            key: 'future',
            label: 'Scheduled Appointments',
            resrcUrl: 'out-patients/future',
            icon: 'fa fa-hospital-o'
        }, {
            key: 'past',
            label: 'Past Appointments',
            resrcUrl: 'out-patients/past',
            icon: 'fa fa fa-user-md'
        }, {
            key: 'unseen',
            label: 'Unseen Appointments',
            resrcUrl: 'out-patients/unseen',
            icon: 'fa fa fa-user-times'
        }];
    }

    getPresTabIcons () {
        return {
            PTP: {
                iconClass: 'mdi mdi-prescription',
                path: 'patient/prescriptions'
            },
            PTM: {
                iconClass: 'mdi mdi-history',
                path: 'patient/documents'
            },
            PTV: {
                iconClass: 'fa fa-heartbeat',
                path: 'patient/vitals'
            },
            PTR: {
                iconClass: 'mdi mdi-file-chart',
                path: 'patient/request-and-result'
            },
            PTN: {
                iconClass: 'fas fa-notes-medical',
                path: 'patient/consultant'
            }
        };
    }

    getCareTakerLabel (ctValue?) {
        const careTakerArr = [
            {value: 1, label: 'Father'},
            {value: 2, label: 'Mother'},
            {value: 3, label: 'Husband'},
            {value: 4, label: 'Wife'},
            {value: 5, label: 'Son'},
            {value: 6, label: 'Daughter'},
            {value: 7, label: 'Friend'},
            {value: 9, label: 'Brother'},
            {value: 10, label: 'Sister'},
            {value: 8, label: 'Other'},
        ];
        return ctValue ? careTakerArr.filter(e => e.value === ctValue)[0].label : careTakerArr;
    }

    getMaritalStatus (msValue?) {
        const msArr = [
            {value: 'C', label: 'Children'},
            {value: 'U', label: 'Un married'},
            {value: 'M', label: 'Married'},
            {value: 'S', label: 'Separated'},
            {value: 'D', label: 'Divorced'},
            {value: 'W', label: 'Widow'}
        ];
        return msValue ? msArr.filter(e => e.value === msValue)[0].label : msArr;
    }

    getTitleCodes (type?) {
        const titleCodes = [
            {value: 'Mr.', label: 'Mr.'},
            {value: 'Mrs.', label: 'Mrs.'},
            {value: 'Miss.', label: 'Miss.'},
            {value: 'Dr.', label: 'Dr.'},
            {value: 'Master.', label: 'Master.'},
            {value: 'Baby.', label: 'Baby.'},
            {value: 'M/s.', label: 'M/s.'}
        ];

        // If type is user we shouldn't show 'Baby.' title code.
        // Because, The all hospitals should not have child labor
        if (type === 'user') {
            _.remove(titleCodes, { value: 'Baby.' });
        }

        return titleCodes;
    }

    getPatientTitleAndName (patientName = '') {
        const patientData = { patientTitle: '', patientName: '-' };

        if (patientName !== '') {
            patientData.patientTitle = _.get(this.getTitleCodes().find(item => patientName.includes(item.label)), 'value', '');
            patientData.patientName = this.toTitleCase(patientName.replace(patientData.patientTitle, ''));
        }

        return patientData;
    }

    getAgeArray (start, end, step) {
        return _.range(start, end, step).map(i => ({
            label: `${i} to ${Math.min(i + step, end)} years`,
            value: `${i}-${Math.min(i + step, end)}`
        }));
    }

    getBloodGroup () {
        return [
            {value: 'O-', label: 'O-'},
            {value: 'O+', label: 'O+'},
            {value: 'A-', label: 'A-'},
            {value: 'A+', label: 'A+'},
            {value: 'B-', label: 'B-'},
            {value: 'B+', label: 'B+'},
            {value: 'AB-', label: 'AB-'},
            {value: 'AB+', label: 'AB+'},
            {value: 'A1+', label: 'A1+'},
            {value: 'A1-', label: 'A1-'},
            {value: 'A1B+', label: 'A1B+'},
            {value: 'A1B-', label: 'A1B-'},
        ];
    }

    getAgeRangeFormat () {
        return [{
            label: 'Day',
            value: 'D'
        }, {
            label: 'Week',
            value: 'W'
        }, {
            label: 'Month',
            value: 'M'
        }, {
            label: 'Year',
            value: 'Y'
        }];
    }

    getAllGenders (allGenderValue?) {
        const allGenderArr = [{
                label: 'All',
                value: 'A'
            }, {
                label: 'Male',
                value: 'M'
            }, {
                label: 'Female',
                value: 'F'
            }, {
                label: 'Transgender',
                value: 'O'
            }, // {
            //    label: 'Infant',
            //    value: 'B'
            // }
        ];
        return allGenderValue ? allGenderArr.filter(e => e.value === allGenderValue)[0].label : allGenderArr;
    }

    getTimeLable (time) {
        return this.getConstantsValue('schedule-timings').filter(obj => obj.value === time)[0].time;
    }

    getAllIntervals () {
        const allIntervals = [];
        for (let i = 0; i < 100; i++) {
            allIntervals.push({value: i + 1, lable: i + 1 + ' Min'});
        }
        return allIntervals;
    }

    getAllWeekDays () {
        return [{value: '1', label: 'Monday', checked: true}, {value: '2', label: 'Tuesday', checked: true}, {value: '3', label: 'Wednesday', checked: true}, {value: '4', label: 'Thursday', checked: true}, {value: '5', label: 'Friday', checked: true}, {value: '6', label: 'Saturday', checked: true}, {value: '7', label: 'Sunday', checked: true}];
    }

    getAllDates () {
        return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
    }

    getAllMonths () {
        return ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
    }

    getYears () {
        const year = new Date().getFullYear();
        const new_year = year + 3;
        const range = [];
        for (let i = 0; i < 10; i++) {
            range.push(new_year - i);
            if (i === 9) {
                return range;
            }
        }
    }

    toastrOpen(type, message, options = {}, title?) {
        this.toastr.clear();
        this.toasrOpenTime = Math.floor((new Date()).getTime() / 1000.0);
        switch (type) {
            case 'I':
                return this.toastr.info(message, title || 'Info', options);
            case 'W':
                return this.toastr.warning(message, title || 'Alert', options);
            case 'S':
                return this.toastr.success(message, title || 'Success!', options);
            case 'E':
                return this.toastr.error(message, title || 'Error', options);
            default:
                break;
        }
    }

    getRITypes () {
        return ['Text', 'Numeric', 'Image'];
    }

    toastrClear() {
        if (this.toasrOpenTime) {
            const epochConval = moment.unix(this.toasrOpenTime); // .format('dddd, MMMM Do, YYYY HH:mm:ss')
            const diffSec = this.getDifBWTwoSecs(epochConval, moment());
            if (diffSec !== '' && diffSec > 2) {
                this.toastr.clear();
            }
        }
    }

    removeEmptyValueFields (dataObj) {
        Object.keys(dataObj).map(objData => {
            const val = dataObj[objData];
            if (val === undefined || val === null || val === false || val === '' || val.length === 0) {
                // tslint:disable-next-line:no-unused-expression
                delete dataObj[objData];
            }
        });
        return dataObj;
    }

    convertViewableAmount (amount) {
        return parseFloat((amount).toFixed(2));
    }

    convertStringToFloat (string) {
        return parseFloat(string);
    }

    convertObjectToParams (obj: Object): HttpParams {
        let params = new HttpParams();
        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            const val = obj[key];
            if (val !== null && val !== undefined) {
              params = params.append(key, val.toString());
            }
          }
        }
        return params;
    }

    getDropDownTHKD (dropdownTH) {
        if (dropdownTH.isPopupOpen()) {
            setTimeout(() => {
                const popup = document.getElementById(dropdownTH.popupId);
                if (popup) {
                    const activeElements = popup.getElementsByClassName('active');
                    if (activeElements.length === 1) {
                        const elem = (activeElements[0] as any);
                        if (typeof elem.scrollIntoViewIfNeeded === 'function') {
                            // non standard function, but works (in chrome)...
                            elem.scrollIntoViewIfNeeded();
                        } else {
                            // do custom scroll calculation or use jQuery Plugin or ...
                            this.scrollIntoViewIfNeededPolyfill(elem as HTMLElement);
                        }
                    }
                }
            });
        }
    }

    scrollIntoViewIfNeededPolyfill(elem: HTMLElement) {
        const parent = elem.parentElement,
            parentComputedStyle = window.getComputedStyle(parent, null),
            parentBorderTopWidth = parseInt(parentComputedStyle.getPropertyValue('border-top-width')),
            parentBorderLeftWidth = parseInt(parentComputedStyle.getPropertyValue('border-left-width')),
            overTop = elem.offsetTop - parent.offsetTop < parent.scrollTop,
            overBottom = (elem.offsetTop - parent.offsetTop + elem.clientHeight - parentBorderTopWidth) > (parent.scrollTop + parent.clientHeight),
            overLeft = elem.offsetLeft - parent.offsetLeft < parent.scrollLeft,
            overRight = (elem.offsetLeft - parent.offsetLeft + elem.clientWidth - parentBorderLeftWidth) > (parent.scrollLeft + parent.clientWidth);

        if ((overTop || overBottom)) {
            parent.scrollTop = elem.offsetTop - parent.offsetTop - parent.clientHeight / 2 - parentBorderTopWidth + elem.clientHeight / 2;
        }

        if ((overLeft || overRight)) {
            parent.scrollLeft = elem.offsetLeft - parent.offsetLeft - parent.clientWidth / 2 - parentBorderLeftWidth + elem.clientWidth / 2;
        }
    }

    openConfirmationDialog(text): Promise<boolean> {
        return new Promise((resolve) => {
          const confirmation = confirm(text ? text : 'Are you sure ?');
          if (confirmation) {
            resolve(true);
          } else {
            resolve(false);
          }
        });
    }

    encodeWSData (wsData) {
        return JSON.parse(atob(wsData));
    }

    toFixedWithZero (value, fractionDigits) {
        const toFixedVal = value.toFixed(fractionDigits);

        return toFixedVal === '0.00' ? '0' : toFixedVal;
    }
}
