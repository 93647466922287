import { Injectable } from '@angular/core';
import { BaseService } from '../base/base.service';

@Injectable({
    providedIn: 'root'
})
export class SupportService extends BaseService {

    public modelPath = 'support';

    createTask (bodyData) {
        return this.httpService.postMethod(`${this.modelPath}/create-task`, bodyData);
    }

}
