import { Injectable } from '@angular/core';

import { ConfigurationService } from '../../services/configuration.service';

@Injectable()
export class UserService extends ConfigurationService {

    public modelPath = 'configuration/users';

    getDTColumns () {
        const self = this;
        return [{
        // 	name: 'user_id',
        // 	title: 'User Id',
        // 	visible: false,
        // 	orderable: false,
        // 	searchable: false,
        // 	data: 'user_id'
        // }, {
            name: 'name',
            title: 'Employee Name',
            data: 'name'
        }, {
            name: 'designation',
            title: 'Designation',
            data: 'designation',
            width: '20%',
            render: function (data: any, type: any, full: any) {
                return `<span class="label label-rounded label-success">${full.designation}</span>`;
            }
        }, {
            name: 'mobile',
            title: 'Mobile',
            data: 'mobile',
            render: function (data: any, type: any, full: any) {
                return `<span class="label label-rounded label-info">${full.mobile}</span>`;
            }
        }, {
            name: 'email',
            title: 'Email',
            data: 'email'
        }, {
            title: 'Login Credentials',
            orderable: false,
            searchable: false,
            render: function (data: any, type: any, full: any) {
                return `<div class="row"><b>U.Name</b> : ${full.username ? full.username : '-'}</div>
					<div class="row"><b>Act.Date</b> : ${full.activation_date ? full.activation_date : '-'}</div>
					<div class="row"><b>Inact.Date</b> : ${full.inactivation_date ? full.inactivation_date : '-'}</div>`;
            }
        }, {
            title: 'Actions',
            orderable: false,
            searchable: false,
            render: function (data: any, type: any, full: any) {
                let accessBtn = '';
                if (self.globalHelper.checkUserAccess('configuration/update-user') || self.globalHelper.checkUserAccess('configuration/update-user-login')) {
                    if (self.globalHelper.checkUserAccess('configuration/update-user-login')) {
                        accessBtn += `<button type="button" data-modelPk="button_${full.user_id}" class="btn btn-${full.iscreate ? 'info' : 'primary'}">
							<i data-modelPk="button_${full.user_id}" class="mdi mdi-key"></i>
							</button>&nbsp;`;
                    }
                    if (self.globalHelper.checkUserAccess('configuration/update-user')) {
                        accessBtn += `<button type="button" class="btn btn-secondary" data-modelPk="${full.user_id}"><i data-modelPk="${full.user_id}" class="icon-pencil"></i></button>`;
                    }
                    return accessBtn;
                }
                return accessBtn;
            }
        }];
    }

    updateUser (bodyDatas) {
        return this.httpService.postMethod(this.modelPath, bodyDatas);
    }

    searchUser (paramDatas) {
        return this.httpService.postMethod('IRISORG/web/v1/user/searchuser', paramDatas);
    }

    getUserCredential (userId) {
        return this.httpService.getMethod(`${this.modelPath}/getlogin?id=${userId}`);
    }

    updateUserCredential (paramDatas) {
        return this.httpService.putMethod(`${this.modelPath}/updatelogin`, paramDatas);
    }

    updateUserTimeOut (bodyDatas) {
        return this.httpService.postMethod(`${this.modelPath}/change_user_timeout`, bodyDatas);
    }

    getUserSearch (optionParams) {
        return this.httpService.getMethod(`${this.modelPath}/get_user_search`, optionParams);
    }

    changePassword (bodyDatas) {
        return this.httpService.postMethod(`${this.modelPath}/change_password`, bodyDatas);
    }

    changePatReqPaymentShowStatus (bodyDatas) {
        return this.httpService.postMethod(`${this.modelPath}/hide_payment`, bodyDatas);
    }

    getCurrUserPackage (optionParams?) {
        return this.httpService.getMethod(`${this.modelPath}/get_user_packages`, optionParams);
    }
}
