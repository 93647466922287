import { Component, OnInit } from '@angular/core';

import { THEME_OPTIONS } from '../../constants/theme-options';
import { GlobalHelper } from '../../helper/global.helper';

@Component({
    selector: 'app-full-width',
    templateUrl: './full-width.component.html',
    styleUrls: ['./full-width.component.css']
})
export class FullWidthComponent implements OnInit {

    options: any = {};

    constructor(public globalHelper: GlobalHelper) { }

    ngOnInit() {
      this.options = JSON.parse(JSON.stringify(THEME_OPTIONS));
      this.options.sidebartype = 'overlay';
    }
}
