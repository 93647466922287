import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Router } from '@angular/router';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { APP_VARIABLES } from '../constants/app-variables';
import { GlobalHelper } from './global.helper';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(private router: Router, private ngbModal: NgbModal,
        private globalHelper: GlobalHelper) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const self = this;
        return next.handle(request).pipe(catchError(resp => {
            switch (resp.status) {
                case 500: case 400: case 422:
                    self.errToastr(resp);
                    return throwError(resp);

                case 401:
                    localStorage.setItem(APP_VARIABLES.lastSeenPage, location.pathname);
                    localStorage.removeItem(APP_VARIABLES.currentUser);
                    if (self.ngbModal.hasOpenModals()) { self.ngbModal.dismissAll(); }
                    self.router.navigate(['login']);
                    setTimeout(() => {
                        self.globalHelper.toastrClear();
                        self.errToastr(resp);
                    }, 150);
                    break;

                case 403:
                    if (self.ngbModal.hasOpenModals()) { self.ngbModal.dismissAll(); }
                    self.router.navigate(['error/403']);
                    break;

                case 404:
                    self.router.navigate(['error/404']);
                    break;

                case 503:
                    localStorage.setItem(APP_VARIABLES.appMaintanence, 'true');
                    self.router.navigate(['error/maintenance']);
                    break;

                default:
                    return throwError(resp);
            }
        }));
    }

    errToastr (response) {
        this.globalHelper.toastrOpen('E', this.getError(response), {
            disableTimeOut: true,
            closeButton: false,
            enableHtml: true,
        });
    }

    getError (response) {
        if (response.error) {
            const errorData = response.error.errors;
            if (errorData) {
                if (typeof errorData === 'object') {
                    const errMsg = [];
                    Object.values(errorData).map(err => {
                        errMsg.push(`<li>${err}</li>`);
                    });
                    return errMsg.join('');
                } else if (typeof errorData === 'string') {
                    return errorData;
                }
            }
        } else {
            return response.message;
        }
    }
}
