import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, takeUntil } from 'rxjs/operators';

import { HttpTrackingService } from '../services/http/httpTracking.service';
import { GlobalHelper } from './global.helper';

@Injectable()
export class HttpRequestTrackingInterceptor implements HttpInterceptor {

  constructor(private trackingService: HttpTrackingService, private globalHelper: GlobalHelper) { }

  intercept<T>(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    const pathname = new URL(req.url).pathname;
    const ABORT_REQUEST_PATHS = this.globalHelper.getConstantsValue('abort_request_paths');
    const CANCELABLE_REQUEST_METHODS = this.globalHelper.getConstantsValue('cancelable_request_methods');

    const isConfirmationURL = ABORT_REQUEST_PATHS.some((path) => pathname.endsWith(`/${path}`));

    // Check if the request should be tracked (Skip all initial POST requests)
    if (CANCELABLE_REQUEST_METHODS.includes(req.method) && isConfirmationURL) {
      this.trackingService.isRequestPending();
    }

    return next.handle(req).pipe(
      takeUntil(this.trackingService.onCancelPendingRequests()), // cancellation logic
      tap(
        event => {
          // Reset tracking status if the response is successful
          if (event instanceof HttpResponse && CANCELABLE_REQUEST_METHODS.includes(req.method)) {
            this.trackingService.resetPendingStatus();
          }
        },
        error => {
          // Reset tracking status if there is an error
          if (CANCELABLE_REQUEST_METHODS.includes(req.method)) {
            this.trackingService.resetPendingStatus();
          }
        }
      )
    );
  }
}
