import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs/Subject';

import { EnvService } from '../env/env.service';

declare const Pusher: any;

@Injectable()

export class PusherService {

  pusher;
  appointmentChannel;
  wsIndicatorClass = 'warning';
  wsIndicatorIconClass = 'rotate-right';
  wsIndicatorText = 'Automatic updates are processing';
  pusherSub = new Subject<object>();
  wsStatus: any = {
    connected: { class: 'success', iconClass: 'update', text: 'Automatic updates are turned on' },
    error: { class: 'danger', iconClass: 'sync-off', text: 'Automatic updates are turned off' },
    connecting: { class: 'warning', iconClass: 'rotate-right', text: 'Automatic updates are processing' }
  };

  constructor(private env: EnvService) {
    this.pusher = new Pusher(env.wsKey, {
      cluster: env.wsCluster,
      encrypted: true,
      wsHost: env.wsHost,
      wsPort: env.wsPort,
      wssPort: env.wssPort,
      httHost: env.httpHost,
      forceTLS: env.forceTLS
      // authEndPoint: 'https://ws.medizura.xyz/broadcasting/auth'
    });
    const self = this;
    ['connecting', 'connected', 'error'].map(status => {
      this.pusher.connection.bind(status, () => {
        self.pusherSub.next({ status });
        self.wsIndicatorClass = self.wsStatus[status].class;
        self.wsIndicatorIconClass = self.wsStatus[status].iconClass;
        self.wsIndicatorText = self.wsStatus[status].text;
      });
    });
    this.appointmentChannel = this.pusher.subscribe('appointment');
  }

  watchPusher(): Observable<any> {
    return this.pusherSub.asObservable();
  }
}
