import { Injectable } from '@angular/core';

import { PatientService } from '../../../services/patient.service';

@Injectable()
export class PatientRequestService extends PatientService {

    public modelPath = 'lab/patient_request';

    getDocFavReqs (optionParams) {
        return this.httpService.getMethod(`${this.modelPath}/get_doctor_based_fav_result_item`, optionParams);
    }

    createPatReqs (bodyDatas) {
        return this.httpService.postMethod(`${this.modelPath}/save_request_items`, bodyDatas);
    }

    getBodyDatas () {
        return {
            columns: [{
                data: 'encounter_id',
                name: 'encounter_id',
                searchable: false,
                orderable: false,
                search: {
                    value: '',
                    regex: false
                }
            }, {
                data: 'items',
                name: 'items',
                searchable: true,
                orderable: true,
                search: {
                    value: '',
                    regex: false
                }
            }, {
                data: 'request_datetime',
                name: 'request_datetime',
                searchable: true,
                orderable: true,
                search: {
                    value: '',
                    regex: false
                }
            }, {
                data: 'createdby_name',
                name: 'createdby_name',
                searchable: true,
                orderable: true,
                search: {
                    value: '',
                    regex: false
                }
            }, {
                data: 'consultant_name',
                name: 'consultantname',
                searchable: true,
                orderable: true,
                search: {
                    value: '',
                    regex: false
                }
            }, {
                data: 'branch_name',
                name: 'branch_name',
                searchable: true,
                orderable: true,
                search: {
                    value: '',
                    regex: false
                }
            }],
            draw: 1,
            length: 10,
            order: [{
                column: 0,
                dir: 'desc'
            }],
            search: {
                value: '',
                regex: false
            },
            start: 0
        };
    }

    getPatReqItems (optionParams) {
        return this.httpService.getMethod(`${this.modelPath}/get_all_patient_request_items`, optionParams);
    }

    printPatReqs (optionParams) {
        return this.httpService.getMethod(`${this.modelPath}/print_request_item`, optionParams);
    }

    printPatReqsWithDoc (optionParams) {
        return this.httpService.getMethod(`${this.modelPath}/print_patient_request`, optionParams);
    }

    checkDuplicateResItem (bodyDatas) {
        return this.httpService.postMethod(`${this.modelPath}/check_duplicate_item`, bodyDatas);
    }

    saveRequestStatus (bodyDatas) {
        return this.httpService.postMethod(`lab/request_status/save_request_status`, bodyDatas);
    }

    getRequestResultUnseenCount (optionParams) {
        return this.httpService.getMethod(`lab/request_status/get_request_count_by_patient`, optionParams);
    }

    setRequestResultSeen (optionParams) {
        return this.httpService.postMethod(`lab/patient_request/seen_request_and_result`, optionParams);
    }

    printHeader () {
        return {};
    }

    printFooter (printSetting , printDatas?) {
        const  content = [];
        if (printSetting && (`${printSetting.verify_by_sign}` === '1' || `${printSetting.lab_technologist_sign}` === '1')) {
            if (`${printSetting.verify_by_sign}` === '1' && `${printSetting.lab_technologist_sign}` === '1') {
            content.push([{
                text: 'Verified by',
                margin: [20, 20, 0, 150],
                alignment: 'left',
                style: 'h1'
                }, {
                text: printDatas ? (printDatas.consultant_name || 'Consultant') : 'lab Technologist',
                margin: [0, 20, 20, 150],
                alignment: 'right',
                style: 'h1'
            }]);
            } else {
                if (`${printSetting.verify_by_sign}` === '1') {
                content.push([{
                    text: 'Verified by',
                    margin: [20, 20, 0, 150],
                    alignment: 'left',
                    style: 'h1'
                    }, {
                    text: ' ',
                    margin: [0, 20, 20, 150],
                    alignment: 'right',
                    style: 'h1'
                }]);
                } else {
                content.push([{
                    text: ' ',
                    margin: [20, 20, 0, 150],
                    alignment: 'left',
                    style: 'h1'
                    }, {
                    text: 'lab Technologist',
                    margin: [0, 20, 20, 150],
                    alignment: 'right',
                    style: 'h1'
                }]);
                }
            }
            return {
                layout: 'noBorders',
                table: {
                    widths: ['*', '*'],
                    body: content
                }
            };
        } else {
            return {};
        }
    }

    printStyle () {
        return {
            headerStyle: {
                margin: []
            },
            h1: {
                fontSize: 12,
                bold: true,
            },
            h2: {
                fontSize: 10,
                bold: true,
            },
            th: {
                fontSize: 8,
                bold: true,
                margin: [0, 3, 0, 3]
            },
            td: {
                fontSize: 7,
                margin: [0, 3, 0, 3]
            },
            normaltxt: {
                fontSize: 8,
            },
            grandtotal: {
                fontSize: 15,
                bold: true,
                margin: [5, 0, 5, 3]
            },
            footertxt: {
                fontSize: 6,
                margin: [20, 0, 0, 0]
            },
            prescriptionTh : {
                bold: true,
                fontSize: 10,
            },
            fs10: {
                fontSize: 10
            }
        };
    }

    async getPMContent(printDatas) {
        const perPageInfo = [], content = [], patientDetails = [], perPageItem = [];
        let qrcode_img, catgDiv, bar_img = [];
        const patDatas = printDatas.patients, printSetting = printDatas.patientRequestPrintSetting;
        if (patDatas.patient_category && patDatas.patient_category.patient_short_code &&
            patDatas.patient_category.patient_category_color) {
            const catgSC = this.globalHelper.convUC(patDatas.patient_category.patient_short_code);
            catgDiv = [{
                canvas: [{
                    type: 'ellipse',
                    x: 8, y: 5,
                    color: patDatas.patient_category.patient_category_color,
                    r1: 10, r2: 10
                }]
            }, {
                text: catgSC,
                color: '#ffffff',
                relativePosition: { x: catgSC.length === 1 ? -4 : -7, y: -1 }
            }];
        } else {
            catgDiv = [{}, {}];
        }
        let bar_image;
        const barCodeElemt = document.getElementById(patDatas.patient_global_int_code);
        const qrCodeElemt = document.getElementById(`qrcode-${patDatas.patient_global_int_code}`);
        if (patDatas.patient_global_int_code && document.contains(barCodeElemt)) {
            bar_image = barCodeElemt.getElementsByTagName('img')[0].getAttribute('src');
        }
        if (qrCodeElemt  && patDatas.patient_global_int_code) { // Check Bar image is empty or not
            qrcode_img = {
                border: [false, true, false, true],
                image : this.imgExport(qrCodeElemt.getElementsByTagName('img')[0], 'qrcode'), // QR code image
                width : 50,
                height : 60,
            };
        } else {
            qrcode_img = {
                border: [false, true, false, true],
                text: ' ',
                width : 30,
                height : 60,
            };
        }

        // let logo;
        // if (printSetting.logo === 1 && printSetting.logoAlign === 'right') {
        // 	logo = [{
        // 		image: 'data:image/jpeg;base64,'+ printSetting.logoUrl,
        // 		height: 20,
        // 		width: 100,
        // 		colSpan: 3,
        // 		}, {}, {}];
        // 	content.push([
        // 		{
        // 		layout: 'noBorders',
        // 		table: {
        // 		widths: ['auto', '*'],
        // 		body: [
        // 			[{
        // 			layout: 'noBorders',
        // 			table: {
        // 				widths: [90, 5, 'auto'],
        // 				body: [
        // 					logo
        // 				]
        // 			},
        // 			}]
        // 		]
        // 		},
        // 	}]);
        // }
            // patientDetails.push(barcode_img)
        patientDetails.push([
            qrcode_img,
            {
                border: [false, true, false, true],
                image: this.textExport(qrCodeElemt ? patDatas.patient_global_int_code : ''),
                height: 60
            },
            {
                border: [false, true, true, true],
                layout: 'noBorders',
                table: {
                    widths: [55, 1, '*'],
                    body: [
                        [{
                            text: `${this.globalHelper.convUC(patDatas.fullname)}${
                                (patDatas.patient_age && patDatas.patient_age.age) ?
                                ` / ${patDatas.patient_age.age}y` : ''} ${patDatas.patient_gender ? '/ ' + this.globalHelper.getAllGenders(patDatas.patient_gender) : ''}`,
                            style: ['td', 'h1'],
                            colSpan: 3
                        }, {}, {}],
                        [patDatas.patient_global_int_code].concat(catgDiv),
                        ['Consultant', ':', {
                            margin: [0, 0, 0, 0],
                            text: this.globalHelper.convUC(printDatas.consultant_name)
                        }]
                    ]
                }
            }, {
                border: [false, true, false, true],
                margin: [10, 0, 0, 0],
                layout: 'noBorders',
                table: {
                    widths: [ 'auto', 1, '*'],
                    body: [['Date', ':', {
                        margin: [0, 0, 0, 0],
                        text: this.globalHelper.getMomentDatas('D-M-Y h:mm A', printDatas.request_datetime),
                    }],
                    ['Address', ':', {
                        margin: [0, 0, 0, 0],
                        text: printDatas.patientAddress.addr_current_address || '-'
                    }]]
                }
            }
        ]);
        perPageInfo.push([{
            layout: {
                hLineColor: function (i, node) {
                    return '#949494';
                },
                vLineColor: function (i, node) {
                    return '#949494';
                }
            },
            table: {
                headerRows: 1,
                widths: [50, 15, '*', 200],
                body: patientDetails
            }
        }]);
        let left = {}, center = {};
        if (`${printSetting.logo}` === '1' && printSetting.logoAlign === 'left') {
            left = {
                image: 'data:image/jpeg;base64,' + printSetting.logoUrl,
                width : 150,
                height : 50,
            };
        }
        if (`${printSetting.logo}` === '1' && printSetting.logoAlign === 'center') {
            center = {
                image: 'data:image/jpeg;base64,' + printSetting.logoUrl,
                width : 150,
                height : 50,
            };
        }
        if (bar_image) {
            bar_img = [
                [
                    left,
                    center,
                    {
                        image : bar_image,
                        // text:'hi',
                        width : 150,
                        height : 50,
                        alignment: 'right'
                    },
                    // {},
                    // {}
                ],
        ];

        } else {
            bar_img = [
                [
                    left,
                    center,
                    {
                        text: ' ',
                        width : 150,
                        height : 50,
                        alignment: 'right'
                    },
                    // {},
                    // {}
                ],
            ];
        }
        if (`${printSetting.logo}` === '1' && printSetting.logoAlign === 'right') {
            bar_img.unshift([{}, {},
                {
                image: 'data:image/jpeg;base64,' + printSetting.logoUrl,
                width : 150,
                height : 50,
                alignment: 'right'
                }]);
        }
        content.push({
            layout: 'noBorders',
            table: {
                widths: ['*', '*', '*'],
                body: bar_img
            }
        });

        content.push(perPageInfo);

        const headers = [{
            margin: [0, 5, 0, -5],
            border: [false, false, false, true],
            rowspan: 2,
            text: 'Request Items',
            style: 'prescriptionTh'
        },
        //  {
        // 	margin: [0, 5, 0, -5],
        // 	border: [false, false, false, false],
        // 	rowspan: 2,
        // 	text: 'Type',
        // 	style: 'prescriptionTh'
        // }
    ];
        if (`${printDatas.show_payment}` === '1') {
            headers.push({
                margin: [0, 5, 0, -5],
                border: [false, false, false, false],
                rowspan: 2,
                text: 'Cost',
                style: 'prescriptionTh'
            });
        }
        perPageItem.push(headers);
        const headerBottom = [];
        for (let i = 0; i < headers.length; i++) {
            const canvasObj = {
                margin: [0, 0, 0, -1],
                border: [false, false, false, false],
                canvas: [{
                    type: 'line', x1: 0, y1: 5, x2: i === 0 ? 70 : 20, y2: 5, lineWidth: 2
                }]
            };
            headerBottom.push(canvasObj);
        }
        perPageItem.push(headerBottom);
        printDatas.items.map((rItems, rIndex) => {
            const rowDatas = [{
                text: [{
                    text: rIndex + 1,
                    fontSize: 10
                }, {
                    text: `. ${rItems.name}\n`,
                    style: 'h2'
                }],
                alignment: 'left',
                style: ['td']
            },
            //  {
            // 	text: rItems.type ? this.globalHelper.toTitleCase(rItems.type) : '-',
            // 	alignment: 'left',
            // 	style: ['td', 'fs10']
            // }
        ];
            if (`${printDatas.show_payment}` === '1') {
                rowDatas.push({
                    text: rItems.cost,
                    alignment: 'right',
                    style: ['td', 'fs10']
                });
            }
            perPageItem.push(rowDatas);
        });
        // console.log(perPageItem)
        content.push({
            layout: {
                hLineWidth: function(i, node) {
                    return (i === 2) ? 1 : 0;
                },
                vLineWidth: function(i, node) {
                    return 0;
                }
            },
            table: {
                headerRows: 2,
                widths: `${printDatas.show_payment}` === '1' ? ['*', 50] : ['*'], // ['*', 50, 50] : ['*', 50]
                body: perPageItem
            }
        });
        return content;

    // 	let content = [], patientDetails = [], perPatientInfo = [],
    // 	perPageItem = [];
    // 	content.push({
    // 		text: this.globalHelper.getMomentDatas('DD-MMMM-YYYY'),
    // 		alignment: 'right',
    // 	});
    //   	let bar_image;
    //  	let barCodeElemt = document.getElementById(printDatas.patient.patient_global_int_code);
    // 	if ( printDatas.patient.patient_global_int_code && document.contains(barCodeElemt) ) {
    // 		bar_image = barCodeElemt.getElementsByTagName('img')[0].getAttribute('src');
    // 	}
    // 	content.push({
    // 		layout: 'noBorders',
    // 		table: {
    // 		widths: ['*', '*'],
    // 		body: [
    // 			[
    // 			{
    // 				text: '',
    // 				width: 10,
    // 				height: 10,
    // 				alignment: 'right',
    // 				margin: [0, 15, 0, 0],
    // 			},
    // 			{
    // 				image: bar_image,
    // 				width: 150,
    // 				height: 50,
    // 				alignment: 'right',
    // 			},
    // 			],
    // 		],
    // 		},
    // 	});
    //   	let catgDiv;
    // 	if ( printDatas.patient.patient_category && printDatas.patient.patient_category.patient_short_code &&
    // 	  printDatas.patient.patient_category.patient_category_color) {
    // 		const catgSC = this.globalHelper.convUC(
    // 			printDatas.patient.patient_category.patient_short_code);
    // 		catgDiv = [{
    // 			canvas: [
    // 			{
    // 			type: 'ellipse',
    // 			x: 8,
    // 			y: 5,
    // 			color: printDatas.patient.patient_category.patient_category_color,
    // 			r1: 10,
    // 			r2: 10,
    // 			},
    // 			],
    // 			},
    // 			{
    // 			text: catgSC,
    // 			color: '#ffffff',
    // 			relativePosition: { x: catgSC.length === 1 ? -8 : -11, y: -1 },
    // 			},
    // 		];
    // 	} else {
    // 		catgDiv = [{ colSpan:2, text: ''
    // 		},{}]
    // 	}
    // 	patientDetails.push([
    // 		{
    // 			border: [false, true, false, true],
    // 			image: this.imgExport(barCodeElemt.getElementsByTagName('img')[0]), //bar_image,//
    // 			width: 33,
    // 			height: 80,
    // 		}, {
    // 			border: [false, true, true, true],
    // 			layout: 'noBorders',
    // 			table: {
    // 				widths: [55, 5, '*'],
    // 				body: [
    // 				[
    // 					{
    // 					text: `${this.globalHelper.convUC(
    // 						printDatas.patient.fullname
    // 					)}${
    // 						printDatas.patient.patient_age &&
    // 						printDatas.patient.patient_age.age
    // 						? ` / ${printDatas.patient.patient_age.age}y`
    // 						: ''
    // 					}`,
    // 					style: ['td', 'h1'],
    // 					colSpan: 3,
    // 					}, {}, {},],
    // 				[printDatas.patient.patient_global_int_code].concat(catgDiv),
    // 				[
    // 					'Consultant',
    // 					':', {
    // 					margin: [0, 0, 0, 0],
    // 					text: this.globalHelper.convUC(printDatas.patient_request.consultant_name),
    // 					},
    // 				],
    // 				],
    // 			},
    // 		}, {
    // 			border: [false, true, false, true],
    // 			margin: [10, 0, 0, 0],
    // 			layout: 'noBorders',
    // 			table: {
    // 				widths: ['auto', 5, 'auto'],
    // 					body: [['', '', '']],
    // 				// body: Array.isArray(vitalDatas) && vitalDatas.length?vitalDatas:[['', '', '']]
    // 			},
    // 		},
    // 	]);
    // 	perPatientInfo.push({
    // 		layout: {
    // 			hLineColor: function (i, node) {
    // 			return '#949494';
    // 			},
    // 			vLineColor: function (i, node) {
    // 			return '#949494';
    // 		},},
    // 		table: {
    // 			headerRows: 1,
    // 			widths: [50, '*', 200],
    // 			body: patientDetails,
    // 		},
    // 	});
    // 	content.push(perPatientInfo);
    // 	perPageItem.push([
    // 		{
    // 			margin: [0, 5, 0, -5],
    // 			border: [false, false, false, false],
    // 			rowspan: 2,
    // 			text: 'Investigation',
    // 			alignment: 'left',
    // 			style: 'prescriptionTh',
    // 		}, {
    // 			margin: [5, 5, 0, -5],
    // 			border: [false, false, false, false],
    // 			text: printDatas.module_id !== 3 ? 'Value' : '',
    // 			alignment: 'center',
    // 			rowspan: 2,
    // 			style: 'prescriptionTh',
    // 		},{
    // 			margin: [5, 5, 0, -5],
    // 			border: [false, false, false, false],
    // 			text: 'Unit',
    // 			alignment: 'center',
    // 			rowspan: 2,
    // 			style: 'prescriptionTh',
    // 		}, {
    // 			margin: [5, 5, 0, -5],
    // 			border: [false, false, false, false],
    // 			text: 'Interpretation',
    // 			alignment: 'center',
    // 			rowspan: 2,
    // 			style: 'prescriptionTh',
    // 		}, {
    // 			margin: [5, 5, 0, -5],
    // 			border: [false, false, false, false],
    // 			text: 'Reference',
    // 			alignment: 'center',
    // 			rowspan: 2,
    // 			style: 'prescriptionTh',
    // 		}, {
    // 			margin: [5, 5, 0, -5],
    // 			border: [false, false, false, false],
    // 			text: 'Remarks',
    // 			alignment: 'center',
    // 			rowspan: 2,
    // 			style: 'prescriptionTh',
    // 		},
    // 	]);
    // 	let headerBottom = [];
    // 	for (let i = 0; i < 6; i++) {
    // 		const canvasObj = {
    // 			margin: [0, 0, 0, -1],
    // 			border: [false, false, false, true],
    // 			canvas: [{ type: 'line', x1: 0, y1: 5, x2: i === 5 ? 100: 70, y2: 5, lineWidth: 2 }],
    // 		},
    // 		normalObj = {
    // 			margin: [0, 0, 0, 0],
    // 			border: [false, false, false, true],
    // 			text: '',
    // 		};
    // 		headerBottom.push(i === 1 && printDatas.module_id === 3 ? normalObj : canvasObj);
    // 	}
    // 	perPageItem.push(headerBottom);
    // 	printDatas.items.map((reqItemDatas, reqIndex) => {
    // 		if (reqItemDatas && Array.isArray(reqItemDatas.group_datas)
    // 		  && reqItemDatas.group_datas.length ) {
    // 			reqItemDatas.group_datas.map((itemDatas, itemIndex) => {
    // 				let prodDetails = [],freqDetails = [];
    // 				prodDetails = [
    // 				{
    // 					border: [false, false, false, false],
    // 					text: [
    // 					{
    // 						text: itemIndex + 1,
    // 						fontSize: 10,
    // 					},
    // 					{
    // 						text: `. ${itemDatas.itemName}`,
    // 						style: 'h2',
    // 					},
    // 					],
    // 					alignment: 'left',
    // 					style: ['td'],
    // 				},
    // 				];

    // 				freqDetails = [
    // 					{
    // 						border: [false, false, false, false],
    // 						text: printDatas.module_id !== 3 ? (itemDatas.value || '-') : '',
    // 						alignment: 'center',
    // 						style: ['td', 'fs10'],
    // 					}, {
    // 						border: [false, false, false, false],
    // 						text: itemDatas.itemUnit ? itemDatas.itemUnit : '-',
    // 						alignment: 'center',
    // 						style: ['td', 'fs10'],
    // 					}, {
    // 						border: [false, false, false, false],
    // 						text: itemDatas.interpretation ? itemDatas.interpretation : '-',
    // 						alignment: 'center',
    // 						style: ['td', 'fs10'],
    // 					}, {
    // 						border: [false, false, false, false],
    // 						text: printDatas.module_id !== 3 ?
    // 								  (itemDatas.referenceValue ? itemDatas.referenceValue.name : '-') :
    // 									(itemDatas.item_reference || '-'),
    // 						alignment: 'center',
    // 						style: ['td', 'fs10'],
    // 					}, {
    // 						border: [false, false, false, false],
    // 						text: itemDatas.remarks ? itemDatas.remarks : '-',
    // 						alignment: 'center',
    // 						style: ['td', 'fs10'],
    // 					},
    // 				];
    // 				perPageItem.push(Array.prototype.concat(prodDetails, freqDetails));
    // 			});
    // 		}
    // 	});
    // 	content.push({
    // 		layout: {
    // 			hLineWidth: function (i, node) {
    // 				return i === 0 ? 3 : 1;
    // 			},
    // 		},
    // 		table: {
    // 			headerRows: 2,
    // 			widths: ['*', 'auto', 70, 70, 70, 100],
    // 			body: perPageItem,
    // 		}
    // 	});
    // 	return content;
    }

    imgExport(imgID, type?) {
        const img = (typeof imgID === 'string') ? document.getElementById(imgID) : imgID,
        canvas = document.createElement('canvas'),
        ctx = canvas.getContext('2d');
        let imgwidth, imgheight;
        if (typeof imgID !== 'string') {
            if (type) {
                canvas.height = canvas.width = 0;
                imgwidth = img.width;
                imgheight = img.height;
                canvas.width = imgwidth;
                canvas.height = imgheight + 15;
                ctx.translate(imgwidth / 2, (imgheight + 15) / 2);
                ctx.rotate(90 * Math.PI / 180);
            } else {
                canvas.width = 68;
                canvas.height = 280;
                ctx.translate(72, 75);  // width
                ctx.rotate(Math.PI * 3 / 2);
                ctx.translate(-210, -75); // Height
            }
        } else {
            canvas.width = img['width'];
            canvas.height = img['height'];
        }
        if (type) {
            ctx.drawImage(img, -(imgwidth / 2), -(imgheight / 2));
        } else {
            ctx.drawImage(img, 0, 0);
        }
        return canvas.toDataURL('image/png');
    }

    textExport = value => {
        const text = value.split('').join(' ');
        const canvas = document.createElement('canvas');
        canvas.width = 50;
        canvas.height = 100;
        const ctx = canvas.getContext('2d');
        ctx.font = 'bold 10px Arial';
        ctx.translate(9, 89);
        ctx.rotate(-0.5 * Math.PI);
        ctx.fillStyle = '#000';
        ctx.fillText(text, 0, 0);
        return canvas.toDataURL();
    }

}
