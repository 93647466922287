import { Action } from '@ngrx/store';
import { PatientModel } from './patients.model';

export enum StoreActionTypes {
  PatientDetailSuccess = '[Patient] Load Patient Detail Success',
  PatientDetailFailure = '[Patient] Load Patient Detail Failure',
  PatientData = '[Patient] Patient Detail Data',
  UpdatePatientNavigation = '[Patient] Patient Navigation Buttons Showing Status',
  UpdateHidePatientNavigation = '[Patient] Patient Navigation Buttons Hiding Status',
}

export class PatientDetailSuccess implements Action {
  readonly type = StoreActionTypes.PatientDetailSuccess;
  constructor(public payload: PatientModel[]) {}
}

export class PatientDetailFailure implements Action {
  readonly type = StoreActionTypes.PatientDetailFailure;
}

export class PatientData implements Action {
  readonly type = StoreActionTypes.PatientData;
  constructor(public payload: { guid: string }) {}
}

export class UpdatePatientNavigation implements Action {
  readonly type = StoreActionTypes.UpdatePatientNavigation;
  constructor(public payload: boolean) {}
}

export class UpdateHidePatientNavigation implements Action {
  readonly type = StoreActionTypes.UpdateHidePatientNavigation;
  constructor(public payload: boolean) {}
}

export type PatientDataActions = PatientDetailSuccess | PatientDetailFailure | PatientData | UpdatePatientNavigation | UpdateHidePatientNavigation;
