import { Injectable } from '@angular/core';

import { PharmacyService } from '../../../services/pharmacy.service';

@Injectable()
export class SaleService extends PharmacyService {

    public modelPath = 'pharmacy/sales';
    public readonly SCOPE_CASH = 'cash';
    public readonly SCOPE_CREDIT = 'credit';
    public readonly SCOPE_CASHONDELIVERY = 'cashondelivery';
    private ahanaOrgId = this.globalHelper.getConstantsValue('app').ahanaOrgId;

    getBodyDatas () {
        return {
            columns: [{
                data: 'sale_id',
                name: 'sale_id',
                searchable: false,
                orderable: true,
                search: {
                    value: '',
                    regex: false
                }
            }, {
                data: 'patient_name',
                name: 'patient_name',
                searchable: true,
                orderable: true,
                search: {
                    value: '',
                    regex: false
                }
            }, {
                data: 'encounter_id',
                name: 'encounter_id',
                searchable: true,
                orderable: true,
                search: {
                    value: '',
                    regex: false
                }
            }, {
                data: 'total_bill_amount',
                name: 'total_bill_amount',
                searchable: true,
                orderable: true,
                search: {
                    value: '',
                    regex: false
                }
            }, {
                data: 'total_paid_amount',
                name: 'total_paid_amount',
                searchable: true,
                orderable: true,
                search: {
                    value: '',
                    regex: false
                }
            }],
            draw: 1,
            length: 10,
            order: [
                {
                    column: 0,
                    dir: 'desc'
                }
            ],
            search: {
                value: '',
                regex: false
            },
            start: 0
        };
    }

    getPendingSaleBills (optionParams) {
        return this.httpService.getMethod(`${this.modelPath}/get_pending_sale_bill`, optionParams);
    }

    getSelectedPatientPrescription (patientGuid, encounterId, orgId) {
        let url = `patient/prescriptions/get_sale_prescription?patient_guid=${patientGuid}&encounter_id=${encounterId}`;

        if (orgId !== this.ahanaOrgId) {
            url += `&org_id=${orgId}`;
        }

        return this.httpService.getMethod(url);
    }

    getLatestSalePatwise (optionParams) {
        return this.httpService.getMethod(`${this.modelPath}/get_latest_sale_patient_wise`, optionParams);
    }

    getPatientWiseSalesDetails (optionParams) {
        return new Promise((res, rej) => {
            this.httpService.getMethod(`${this.modelPath}/get_patientwise_sale`, optionParams).subscribe(result => {
                res(result);
            }, error => {
                rej(error);
            });
        });
    }

    getsalesPendingPaymt (optionParams) {
        return this.httpService.getMethod(`${this.modelPath}/get_sale_pending_payment_amount`, optionParams);
    }

    getAllowIPSaleCash (optionParams?) {
        return this.httpService.getMethod(`${this.modelPath}/get_allow_ip_patient_sale_cash`, optionParams);
    }

    printHeader() {
        return {
            text: '',
            margin: 0,
            alignment: 'center'
        };
    }

    printFooter(sale) {
        const orgId = this.globalHelper.getCurData('curOrgId'),
            { ajHospitalOrgId } = this.globalHelper.getConstantsValue('app'),
            companyTxt = orgId === ajHospitalOrgId
                ? 'Powered By Sumanas Technologies' : 'Software designed by www.sumanastech.com',
            print_setting = this.globalHelper.getObjectValue(sale, 'salePrintSetting', {}),
            footer = print_setting.footer ? (print_setting.footer_text + `\n${companyTxt}`) : '';

        return {
            text: footer,
            fontSize: 7,
            margin: 0,
            alignment: 'center'
        };
    }

    printStyle() {
        return {
            h1: {
                fontSize: 11,
                bold: true,
            },
            h2: {
                fontSize: 8,
                bold: true,
            },
            th: {
                fontSize: 8,
                bold: true,
                margin: [0, 3, 0, 3]
            },
            td: {
                fontSize: 7,
                margin: [0, 3, 0, 3]
            },
            normaltxt: {
                fontSize: 8,
            },
            total: {
                fontSize: 10,
                bold: true,
                margin: [5, 0, 5, 3]
            },
            grandtotal: {
                fontSize: 15,
                bold: true,
                margin: [5, 0, 5, 3]
            }
        };
    }

    async getPMContent(sale) {
        let content = this.globalHelper.getObjectValue(sale, 'saleData.saleReturnOnly', false)
            ? [] : await this.getSalePrintContent(sale, true);

        if (sale.saleReturnDatas && sale.saleReturnDatas.length) {
            const saleReturnContent = await this.getSaleReturnPrintContent(sale, true);
            content = content.concat(saleReturnContent);
        }

        return content;
    }

    async getPatientLatestSale (openSale, model, laststBillLoading, billModel?) {
        if (openSale) {
            laststBillLoading.value = true;
            const self = this,
                paramsOptions = this.globalHelper.convertObjectToParams({
                    patient_id: model.patient_id, ...(model.org_id !== this.ahanaOrgId && { org_id: model.org_id })
                });

            await this.getLatestSalePatwise({params: paramsOptions}).subscribe((result: any) => {
                result.sales.map((saleData: any) => {
                    saleData.bill_no_with_patient += ` (${self.globalHelper.getMomentDatas('app-date-time', saleData.sale_date)})`;
                });
                model.latst_sales = result.sales;
                laststBillLoading.value = false;
            }, error => {
                laststBillLoading.value = false;
            });
        } else {
            model.latst_sales = [];
            model.return_Bill = '';
            model.current_sale_return_data = {
                openNewRtnSale: false
            };
            model.all_sale_return_data = {
                total_item_vat_amount: '0.00',
                total_item_sale_amount: '0.00',
                total_item_discount_percent: 0,
                total_item_discount_amount: '0.00',
                total_item_amount: '0.00',
                roundoff_amount: '0.00',
                bill_amount: '0.00',
                return_items: []
            };
            if (model.indentReturn && model.indentReturn.length > 0) { model.indentReturn = []; }
            model.sale_return_data = [];
        }
        if (billModel) {
            billModel.billId = {};
        }
    }

    valueAssignedModel (model, saleDetails) {
        model.current_sale_return_data = saleDetails;
        if (model.current_sale_return_data.mobile_no === null) {
            // tslint:disable-next-line:no-unused-expression
            delete model.current_sale_return_data.mobile_no;
        }
        model.current_sale_return_data.product_items = model.current_sale_return_data.items.map((e) => {
            e.batch_no = e.batch.batch_no;
            e.bill_no = saleDetails.bill_no;
            e.batch_id = e.batch.batch_id;
            e.expiry_date = e.batch.expiry_date;
            e.sale_quantity = e.quantity;
            e.old_quantity = e.quantity;
            e.selected = false;
            e.quantity = 0;
            e.discount_percentage = e.discount_percentage;
            e.discount_amount = 0;
            e.taxable_value = 0;
            e.cgst_amount = 0;
            e.sgst_amount = 0;
            e.total_amount = 0;
            e.total_returned_quantity = parseInt(e.total_returned_quantity);
            return e;
        });
        // tslint:disable-next-line:no-unused-expression
        delete model.current_sale_return_data.items, model.current_sale_return_data.saleBilling, model.current_sale_return_data.patient_address, model.current_sale_return_data.salePrintSetting;
        model.current_sale_return_data['sale_return_date'] = this.globalHelper.getMomentDatas('YYYY-M-DD HH:mm:ss', model.sale_date);
        // model.sale_return_data.allowNewSale = false;
        model.current_sale_return_data.total_item_vat_amount = '0.00';
        model.current_sale_return_data.total_item_sale_amount = '0.00';
        model.current_sale_return_data.total_item_discount_percent = 0;
        model.current_sale_return_data.total_item_discount_amount = '0.00';
        model.current_sale_return_data.total_item_amount = '0.00';
        model.current_sale_return_data.roundoff_amount = '0.00';
        model.current_sale_return_data.bill_amount = '0.00';
        model.current_sale_return_data.openNewRtnSale = true;
        model.isRemoveSaleBill = true;
    }

}
