import { Injectable } from '@angular/core';

import { PharmacyService } from '../../services/pharmacy.service';

@Injectable()
export class SaleReturnsService extends PharmacyService {

    public modelPath = 'pharmacy/sale_returns';

    getBodyDatas () {
        return {
            columns: [{
                data: 'sale_ret_id',
                name: 'sale_ret_id',
                searchable: false,
                orderable: false,
                search: {
                    value: '',
                    regex: false
                }
            }, {
                data: 'patient_name',
                name: 'patient_name',
                searchable: false,
                orderable: false,
                search: {
                    value: '',
                    regex: false
                }
            }, {
                data: 'bill_no',
                name: 'bill_no',
                searchable: false,
                orderable: false,
                search: {
                    value: '',
                    regex: false
                }
            }, {
                data: 'sale_bill_no',
                name: 'sale_bill_no',
                searchable: false,
                orderable: false,
                search: {
                    value: '',
                    regex: false
                }
            }, {
                data: 'sale_payment_type',
                name: 'sale_payment_type',
                searchable: false,
                orderable: false,
                search: {
                    value: '',
                    regex: false
                }
            }, {
                data: 'sale_return_date',
                name: 'sale_return_date',
                searchable: true,
                orderable: true,
                search: {
                    value: '',
                    regex: false
                }
            }, {
                data: 'bill_amount',
                name: 'bill_amount',
                searchable: false,
                orderable: false,
                search: {
                    value: '',
                    regex: false
                }
            }, {
                data: 'total_item_amount',
                name: 'total_item_amount',
                searchable: false,
                orderable: false,
                search: {
                    value: '',
                    regex: false
                }
            }, {
                data: 'total_item_discount_amount',
                name: 'total_item_discount_amount',
                searchable: false,
                orderable: false,
                search: {
                    value: '',
                    regex: false
                }
            }, {
                data: 'roundoff_amount',
                name: 'roundoff_amount',
                searchable: false,
                orderable: false,
                search: {
                    value: '',
                    regex: false
                }
            }],
            draw: 1,
            length: 10,
            order: [
                {
                    column: 5,
                    dir: 'desc'
                }
            ],
            search: {
                value: '',
                regex: false
            },
            start: 0
        };
    }

    billSearch (paramDatas) {
        let reqParams = paramDatas.searchValue;
        if (paramDatas.patientId) {
            reqParams = paramDatas.searchValue + '&patient_id=' + paramDatas.patientId;
        }
        return this.httpService.getMethod('pharmacy/sales/get_sale_billno?bill_no=' + reqParams);
    }

    getSaleBill (saleId, paramData = {}) {
        return this.httpService.getMethod(`pharmacy/sales/${saleId}`, paramData);
    }

    saveSaleReturn (paramDatas) {
        return this.httpService.postMethod('pharmacy/sale_returns/savesalereturn', paramDatas);
    }

    getAllProducts() {
        return this.httpService.getMethod('IRISORG/web/v1/pharmacyproduct?fields=product_id,full_name&not_expired=1&full_name_with_stock=1');
    }

    getAllHSNCodeLists () {
        return this.httpService.getMethod('IRISORG/web/v1/hsn/gethsncodelist?status=1&deleted=false');
    }

    searchedBillNo(searchWord) {
        return this.httpService.getMethod('IRISORG/web/v1/pharmacysale/getsalebillno?bill_no=' + searchWord + '&addtfields=sale_bill_search');
    }

    getSelectedSale(saleId) {
        return this.httpService.getMethod('IRISORG/web/v1/pharmacysales/' + saleId + '?addtfields=sale_return');
    }

    getSelectedProductDetails(productId) {
        return this.httpService.getMethod('IRISORG/web/v1/pharmacyproducts/' + productId + '?fields=product_name,product_location,product_reorder_min,full_name,salesVat,salesPackageName,availableQuantity,generic_id,product_batches,hsnCode,originalQuantity,gst&addtfields=pharm_sale_prod_json&full_name_with_stock=1');
    }

    getSelectedSaleReturn(saleId) {
        return this.httpService.getMethod('IRISORG/web/v1/pharmacysalereturns/' + saleId + '?addtfields=sale_return');
    }

    getAllSaleRenturns (options) {
        let reqParams = '&pageIndex=' + options.pIndex + '&pageSize=' + options.pSize;
        if (options.searchValue) {
            reqParams = reqParams + '&s=' + options.searchValue;
        }
        if (options.sRtnDate) {
            reqParams = reqParams + '&d=' + options.sRtnDate;
        }
        return this.httpService.getMethod('IRISORG/web/v1/pharmacysalereturn/getsalereturn?addtfields=sale_return_list' + reqParams);
    }

    printHeader() {
        return {
            text: '',
            margin: 0,
            alignment: 'center'
        };
    }

    printFooter() {
        return {
            text: '24 HOURS PHARMACY SERVICE AVAILABLE \nPowered By: Sumanas Technologies Pvt Ltd',
            fontSize: 7,
            margin: 0,
            alignment: 'center'
        };
    }

    printStyle() {
        return {
            h1: {
                fontSize: 11,
                bold: true,
            },
            h2: {
                fontSize: 9,
                bold: true,
            },
            th: {
                fontSize: 9,
                bold: true,
                margin: [0, 3, 0, 3]
            },
            td: {
                fontSize: 8,
                margin: [0, 3, 0, 3]
            },
            normaltxt: {
                fontSize: 9,
            },
            grandtotal: {
                fontSize: 15,
                bold: true,
                margin: [5, 3, 5, 3]
            }
        };
    }

    async getPMContent(printData) {
        let content = await this.getSaleReturnPrintContent(printData, false);

        // If the sale return form have sale data means, We should pass that to make sale print content
        if (printData.saleData) {
            const saleContent = await this.getSalePrintContent(printData.saleData, false, true);
            content.push({layout: 'noBorders', text: '', pageBreak: 'after'});
            content = content.concat(saleContent);
        }

        return content;
    }

}
