import { APP_VARIABLES } from 'src/app/constants';
import { PatientDataActions, StoreActionTypes } from './patients.actions';
import { PatientModel } from './patients.model';

export interface PatientState {
    patient: PatientModel[];
    isDisabledPatientNavigation: boolean;
    hidePatientNavigation: boolean;
}

export const initialPatientState: PatientState = {
    patient: JSON.parse(sessionStorage.getItem(APP_VARIABLES.patientDetails)),
    isDisabledPatientNavigation: false,
    hidePatientNavigation: false
};

export function PatientReducer(
    state = initialPatientState,
    action: PatientDataActions
): PatientState {
    switch (action.type) {
        case StoreActionTypes.PatientDetailSuccess:
            return {
                ...state,
                patient: action.payload
            };

        case StoreActionTypes.PatientDetailFailure:
            return {
                ...state,
                patient: null
            };

        case StoreActionTypes.PatientData:
            return {
                ...state
            };

        case StoreActionTypes.UpdatePatientNavigation:
            return {
                ...state,
                isDisabledPatientNavigation: action.payload
            };

        case StoreActionTypes.UpdateHidePatientNavigation:
            return {
                ...state,
                hidePatientNavigation: action.payload
            };

        default:
            return state;
    }
}
