import { Component, AfterViewInit, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';

import { Store } from '@ngrx/store';

import { ToastrService } from 'ngx-toastr';
import { filter, map, mergeMap } from 'rxjs/operators';

import { PatientRequestService } from 'src/app/modules/patient/request-and-result/services';

import {
    CONFIGROUTES,
    PHARMACYROUTES,
    MYWORKSGROUTES,
    PATIENTROUTES
} from './menu-datas';
import { EncounterService } from '../../modules/patient/encounters/services/encounter/encounter.service';
import { VitalService } from '../../modules/patient/vitals/services/vital.service';
import { NoteService } from '../../modules/patient/notes/services/note.service';
import { GlobalHelper } from '../../helper/global.helper';
import { PatientModel } from 'src/app/modules/patient/store/Patient/patients.model';
import { patientSelector } from 'src/app/modules/patient/store/Patient/patients.selector';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.css'],
    providers: [
        EncounterService,
        NoteService,
        VitalService,
        PatientRequestService
    ]
})
export class SidebarComponent implements AfterViewInit, OnInit, OnDestroy {

    showMenu = 'Administrative Module';
    showSubMenu = '';
    showDepthMenu = '';
    public sidebarnavItems: any[];
    defaultPatMenus = ['Timeline', 'Encounters', 'Details'];
    noteUnseen = 0;
    vitalUnseen = 0;
    requestUnseen = 0;
    seenApiCall = false;
    storeWatcher;
    routerSubscriber;
    // this is for the open close

    constructor(private router: Router, private activatedRoute: ActivatedRoute,
        private modelService: EncounterService, private toastr: ToastrService,
        private noteService: NoteService, private vitalService: VitalService,
        private globalHelper: GlobalHelper, private patientRequestService: PatientRequestService,
        private store: Store<{ data: PatientModel[] }>) {
        const self = this;
        this.routerSubscriber = this.router.events.pipe(filter(event => event instanceof NavigationEnd))
            .pipe(map(() => this.activatedRoute)).pipe(map(route => {
            while (route.firstChild) {
                route = route.firstChild;
            }
            return route;
        })).pipe(filter(route => route.outlet === 'primary'))
            .pipe(mergeMap(route => route.data)).subscribe(event => {
            self.showMenu = event.mainMenu;
            self.showSubMenu = event.subMenu || (event.urls && event.urls.length === 2 ? event.urls[1].title : '');
            self.showDepthMenu = event.depthMenu || (event.urls && event.urls.length === 2 ? event.urls[1].title : '');
            self.globalHelper.showSearch = false;
            self.setMenuDatas();
        });
    }

    ngOnInit () {
        const self = this;
        this.storeWatcher = this.store.select(patientSelector).subscribe((results: any) => {
            if (results && (typeof results.note_unseen !== 'undefined' || typeof results.vital_unseen !== 'undefined' ||
                typeof results.request_unseen !== 'undefined')) {
                self.noteUnseen =  results.note_unseen;
                self.vitalUnseen = results.vital_unseen;
                self.requestUnseen = results.request_unseen;
            }
        });
    }

    ngAfterViewInit () {
        this.removeEmptyMenus();
        const self = this;
        setTimeout(() => {
            if (self.globalHelper.getCurData('pat-dtl').haveActiveEncounter &&
                self.router.url.search('/patient/') !== -1) {
                self.getUnseenRecords(self.globalHelper.getCurData('pat-dtl').patient_guid);
            }
        }, 600);
    }

    async getUnseenRecords (patGuid) {
        if (!this.seenApiCall) {
            this.seenApiCall = true;
            const self = this, paramsOptions = this.globalHelper.convertObjectToParams({ patient_guid: patGuid });
            await this.noteService.getNotesUnseenCount({ params: paramsOptions }).subscribe((notesResults: any) => {
                self.globalHelper.updatePatDatas({note_unseen: notesResults.noteCount});
            }, (error) => {
                self.globalHelper.updatePatDatas({note_unseen: 0});
            });
            await this.vitalService.getVitalUnseenCount({ params: paramsOptions }).subscribe((vitalResults: any) => {
                self.globalHelper.updatePatDatas({vital_unseen: vitalResults.vitalCount});
            }, (error) => {
                self.globalHelper.updatePatDatas({vital_unseen: 0});
            });
            await this.patientRequestService.getRequestResultUnseenCount({ params: paramsOptions }).subscribe((requestResults: any) => {
                self.globalHelper.updatePatDatas({request_unseen: requestResults.requestCount});
            }, (error) => {
                self.globalHelper.updatePatDatas({request_unseen: 0});
            });
        }
    }

    setMenuDatas () {
        const self = this;
        if (this.toastr.toasts[0] && this.toastr.toasts[0].message !== 'Network connection failed' && this.toastr.toasts[0].message.search('success') === -1) {
            this.globalHelper.toastrClear();
        }
        if (this.router.url.search('/configuration/') !== -1) {
            const configMenus = CONFIGROUTES.filter(sidebarnavItem => sidebarnavItem);
            if (Array.isArray(this.sidebarnavItems) && this.sidebarnavItems.length !== configMenus.length) {
                document.getElementById('left-Slider').scroll(0, 0);
            }
            this.sidebarnavItems = configMenus;
            setTimeout(() => {
                self.removeEmptyMenus();
            });
        } else if (this.router.url.search('/pharmacy/') !== -1) {
            const pharmacyMenus = PHARMACYROUTES.filter(sidebarnavItem => sidebarnavItem);
            if (Array.isArray(this.sidebarnavItems) && this.sidebarnavItems.length !== pharmacyMenus.length) {
                document.getElementById('left-Slider').scroll(0, 0);
            }
            const userAccessUrls = this.globalHelper.getCurData('user-access-urls');
            if (Array.isArray(userAccessUrls) && userAccessUrls.length) {
                const saleMenuDatas = pharmacyMenus[0].submenu[0];
                if (!(userAccessUrls.some(uAccData => uAccData.resource_url === saleMenuDatas.path.slice(1)))) {
                    if (userAccessUrls.some(uAccData => uAccData.resource_url === 'pharmacy/sales')) {
                        pharmacyMenus[0].submenu[0].path = '/pharmacy/sales';
                    }
                }
                const purchaseMenuDatas = pharmacyMenus[1].submenu[0];
                if (!(userAccessUrls.some(uAccData => uAccData.resource_url === purchaseMenuDatas.path.slice(1)))) {
                    if (userAccessUrls.some(uAccData => uAccData.resource_url === 'pharmacy/purchases')) {
                        pharmacyMenus[1].submenu[0].path = '/pharmacy/purchases';
                    }
                }
            }
            this.sidebarnavItems = pharmacyMenus;
            setTimeout(() => {
                self.removeEmptyMenus();
            });
        } else if (this.router.url.search('/my-works/') !== -1) {
            const myWorkMenus = MYWORKSGROUTES.filter(sidebarnavItem => sidebarnavItem);
            if (Array.isArray(this.sidebarnavItems) && this.sidebarnavItems.length !== myWorkMenus.length) {
                document.getElementById('left-Slider').scroll(0, 0);
            }
            this.sidebarnavItems = myWorkMenus;
            setTimeout(() => {
                self.removeEmptyMenus();
            });
        } else if (this.router.url.search('/patient/') !== -1) {
            const patientGUID = this.globalHelper.getParamsData();
            if (patientGUID) {
                const paramsOptions = this.globalHelper.convertObjectToParams({ patient_guid: patientGUID });
                this.modelService.getPatientActiveStatus({params: paramsOptions}).subscribe((result: any) => {
                    self.sidebarnavItems = PATIENTROUTES.filter(sidebarnavItem => {
                        sidebarnavItem.disabled = !result.success;
                        const pathSplitArr = sidebarnavItem.path.split('/');
                        if (pathSplitArr[3]) {
                            sidebarnavItem.path = `/${pathSplitArr[1]}/${pathSplitArr[2]}/${patientGUID}`;
                        } else {
                            sidebarnavItem.path = `${sidebarnavItem.path}/${patientGUID}`;
                        }
                        return sidebarnavItem;
                    });
                });
            }
        } else {}
        window.scroll(0, 0);
    }

    removeEmptyMenus () {
        $('li.sidebar-item > ul:empty').closest('li').remove();
        $('li.sidebar-item > ul:empty').closest('li').remove();
    }

    addExpandClass(element: any) {
        if (element === this.showMenu) {
            this.showMenu = '0';
            document.getElementById('left-Slider').scroll(0, 0);
        } else {
            this.showMenu = element;
        }
    }

    addActiveClass(element: any) {
        if (element.submenu.length) {
            if (element.title === this.showSubMenu) {
                this.showSubMenu = '0';
                document.getElementById('left-Slider').scroll(0, 0);
            } else {
                this.showSubMenu = element.title;
            }
        }
    }

    ngOnDestroy () {
        if (this.storeWatcher) {
            this.storeWatcher.unsubscribe();
        }
        if (this.routerSubscriber) {
            this.routerSubscriber.unsubscribe();
        }
    }

}
